import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RestApiService} from "../services/rest-api.service";
import {MessageService} from "../services/message.service";
import {UtilsService} from "../services/utils.service";
import {RuleAccessPopupComponent} from "../shared/rule-access-popup/rule-access-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-create-dip-rules',
    templateUrl: './create-dip-rules.component.html',
    styleUrls: ['./create-dip-rules.component.css']
})
export class CreateDipRulesComponent implements OnInit {
    createRules: FormGroup = new FormGroup({});
    state = [];
    dataLevel = [];
    dataUnit = [];
    subUnit = [];
    dataType = [];
    designation = [];
    appPermissions = {}
    selectedPermissions = [];
    allPermissions = [];
    levelName: string;
    isEdit: boolean = false;
    clientApps = [];
    appClientId: string;
    areaType: string;

    constructor(private formBuilder: FormBuilder, private apiService: RestApiService, private messageService: MessageService,
                private utils: UtilsService, private dialog: MatDialog, private activatedRoute: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
        this.getClientApps()
        this.createRules = this.formBuilder.group({
            id: new FormControl(''),
            client_app_id: new FormControl('', [Validators.required]),
            state_id: new FormControl('', [Validators.required]),
            data_type_id: new FormControl('', [Validators.required]),
            data_level_id: new FormControl('', [Validators.required]),
            data_unit_id: new FormControl('', [Validators.required]),
            sub_unit_id: new FormControl('', [Validators.required]),
            designation_id: new FormControl('', [Validators.required]),
            permission_ids: new FormControl('', [Validators.required]),
        });
        this.onFormChanges();
        this.checkParams();
    }

    onFormChanges(): void {
        this.createRules.get('client_app_id').valueChanges.subscribe(value => {
            this.state = []
            this.createRules.get('state_id').setValue('')
            if (value) {
                this.getCountryState();
                this.clientApps.forEach(app => {
                    if (value === app.id) {
                        this.appClientId = ''
                        this.appClientId = app.client_id
                    }
                })
            }
        });
        this.createRules.get('state_id').valueChanges.subscribe(value => {
            this.createRules.get('data_type_id').setValue('')
            this.dataType = [];
            if (value) {
                this.getDataType();
            }
        });
        this.createRules.get('data_type_id').valueChanges.subscribe(value => {
            this.createRules.get('data_level_id').setValue('')
            this.dataLevel = [];
            if (value && this.appClientId) {
                this.getLevel()
            }
        });
        this.createRules.get('data_level_id').valueChanges.subscribe(value => {
            this.areaType = '';
            this.createRules.get('data_unit_id').setValue('')
            this.dataUnit = [];
            if (value) {
                this.dataLevel.forEach(level => {
                    if (level.id === value) {
                        this.levelName = level.name
                        this.areaType = level.level_class
                    }
                })
                this.getUnits(value);
            }
        });
        this.createRules.get('data_unit_id').valueChanges.subscribe(value => {
            this.createRules.get('sub_unit_id').setValue('')
            this.subUnit = [];
            if (value) {
                this.getSubUnits(value)
            }
        });
        this.createRules.get('sub_unit_id').valueChanges.subscribe(value => {
            this.createRules.get('designation_id').setValue('')
            this.designation = [];
            if (value) {
                this.getDesignations(value);
            }
        });
        this.createRules.get('designation_id').valueChanges.subscribe(value => {
            if (value) {
                this.getDipPermissions(this.createRules.get('client_app_id').value, this.createRules.get('data_level_id').value)
            }
        });
    }

    getCountryState(): void {
        this.apiService.allottedCountryStates().subscribe((response: any) => {
            this.state = response.data;
        });
    }

    getDipPermissions(clientAppId, dataLevel): void {
        this.apiService.getDipPermission(clientAppId, dataLevel).subscribe(response => {
            this.appPermissions = Object.values(response)[2];
            this.allPermissions = response.ids;
        }, error => {
            this.messageService.errorSnackBar(error.message);
        });
    }

    getValue(items: any): any {
        return items;
    }


    getLevel(): void {
        this.apiService.dataLevel(this.appClientId).subscribe((res: any) => {
            this.dataLevel = res.data;
        }, error => {
            this.messageService.errorSnackBar(error);
        });
    }

    getDataType(): void {
        this.apiService.getDataTypes().subscribe((response: any) => {
            this.dataType = response.data;
        })
    }

    permissionSelection(event: any, id): void {
        if (event.checked) {
            this.selectedPermissions.push(id);
        } /* unselected */ else {
            let i = 0;
            this.selectedPermissions.forEach((value: any) => {
                if (value === id) {
                    this.selectedPermissions.splice(i, 1);
                    return;
                }
                i++;
            });
        }
        this.createRules.get('permission_ids').setValue(this.selectedPermissions);
    }

    selectAllPermission(event): void {
        if (event.checked) {
            this.selectedPermissions = this.allPermissions;
            this.createRules.get('permission_ids').setValue(this.allPermissions);
        } else {
            this.selectedPermissions = [];
            this.createRules.get('permission_ids').setValue(this.selectedPermissions);
        }
    }

    createDipRule(): void {
        this.apiService.createDipRule(this.createRules.value).subscribe((response: any) => {
            if (response) {
                this.messageService.closableSnackBar('Rule has been saved');
                this.createRules.get('client_app_id').setValue('');
                this.selectedPermissions = [];
                this.createRules.markAsUntouched();
                window.history.back();
            }
        }, error => {
            this.messageService.errorSnackBar(error);
        })
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(RuleAccessPopupComponent, {
            disableClose: false,
            width: '350px',
            data: {message: 'Are you sure, you want to give access to all the users of these designation?'}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.createDipRule();
            } else {
                this.messageService.closableSnackBar('Please Review Again', 3000);
            }

        });
    }

    checkParams(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params['rule']) {
                this.levelName = params['levelName'];
                this.appClientId = params['clientId'];
                this.createRules.get('client_app_id').setValue(parseInt(params['clientApp']));
                this.createRules.get('state_id').setValue(parseInt(params['state']));
                this.createRules.get('data_type_id').setValue(parseInt(params['type']));
                this.createRules.get('data_level_id').setValue(parseInt(params['level']));
                this.createRules.get('data_unit_id').setValue(parseInt(params['unit']));
                this.createRules.get('sub_unit_id').setValue(parseInt(params['subunit']));
                this.createRules.get('designation_id').setValue(parseInt(params['designation']));
                this.createRules.get('id').setValue(parseInt(params['rule']));
                this.getDipRules();
                this.isEdit = true;
            }
        });
    }

    getDipRules(): void {
        this.apiService.getDipRules(this.createRules.get('client_app_id').value, this.createRules.get('state_id').value, this.createRules.get('data_type_id').value, this.createRules.get('data_level_id').value,
            this.createRules.get('data_unit_id').value, this.createRules.get('sub_unit_id').value, this.createRules.get('designation_id').value, '5', '0').subscribe((response: any) => {
            if (response) {
                this.selectedPermissions = response.rules[0]?.permissions.id;
                this.createRules.get('permission_ids').setValue(this.selectedPermissions);
            }
        }, error => {
            this.messageService.errorSnackBar(error.message);
        });
    }

    getUnits(value) {
        const data = {
            type: 'Unit',
            country_state_id: this.createRules.get('state_id').value,
            area_type: this.areaType,
            data_type: this.createRules.get('data_type_id').value,
            data_level: value,
            unit_id: null,
            sub_unit_id: null
        };
        this.apiService.getFilterOptions(data).subscribe(reply => {
            const response = reply as any;
            this.dataUnit = response.data;
        }, error => {
            this.messageService.errorSnackBar(error.error.message);
        });
    }

    getSubUnits(dataUnit) {
        const data = {
            type: 'SubUnit',
            country_state_id: this.createRules.get('state_id').value,
            area_type: this.areaType,
            data_type: this.createRules.get('data_type_id').value,
            data_level: this.createRules.get('data_level_id').value,
            unit_id: dataUnit,
            sub_unit_id: null
        };
        this.apiService.getFilterOptions(data).subscribe((reply: any) => {
            this.subUnit = reply.data;
        }, error => {
            this.messageService.errorSnackBar(error.error.message,);
        });
    }


    getDesignations(subUnit) {
        const data = {
            type: 'Designation',
            country_state_id: this.createRules.get('state_id').value,
            area_type: this.areaType,
            data_type: this.createRules.get('data_type_id').value,
            data_level: this.createRules.get('data_level_id').value,
            unit_id: this.createRules.get('data_unit_id').value,
            sub_unit_id: subUnit
        };
        this.apiService.getFilterOptions(data).subscribe(reply => {
            const response = reply as any;
            this.designation = response.data;
        }, error => {
            this.messageService.errorSnackBar(error.error.message);
        });
    }

    getClientApps() {
        this.apiService.clientApps().subscribe(response => {
            const res = response as any;
            this.clientApps = res.data;
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
        });
    }
}
