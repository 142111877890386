import { Injectable } from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) { }
  init() {
    this.matIconRegistry.addSvgIcon(
      'menu-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/menu-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/bjp-logo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/plus.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'view-sso',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/view-details.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'upload',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/upload-file.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'delete-user',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/delete-user.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'delete',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'cross',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/cross.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'deactivate-user',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/deactivate-user.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'edit-user',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'search-grey',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/search-b.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'search',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/search-w.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'last-edited',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/last-edited.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'level-wise',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/level-wise.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'download',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/download.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'dip',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/dip.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'select-arrow',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/select-arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'location',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/location.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'index-title-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/user-management.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'error-500',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/server-error.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'error-401',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/unauthorized.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'error-404',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/error-404.svg')
    );
  }
}
