<div *ngIf="isLoading | async" class="overlay">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="loader-top-margin" *ngIf="loading">
    <div class="loader-container">
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <span class="loading">Loading...</span>
    </div>
</div>
