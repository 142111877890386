<div class="roles-container container-md mt-3">
    <mat-drawer-container class="example-margin view-roles-container">
        <mat-drawer mode="side" opened style="background: #33404D; width: 300px;">
                <ng-container *ngIf="isLoading">
                    <ngx-skeleton-loader
                            [theme]="{ 'border-radius': '5px', height: '50px','background-color': '#f8f4f4', border: '1px solid white' }"
                            animation="pulse" count="10"></ngx-skeleton-loader>
                </ng-container>
            <div class="nav-bar-content d-flex flex-column no-app">
                <div class="d-flex add-app-title {{getActiveRoleClass(role.name)}} justify-content-between align-items-center"
                     *ngFor="let role of roles ; let i = index">
                        <span class="cursor-pointer app-selected"
                              (click)="setActiveRole(role.name)">{{role.name.replace('_', ' ').toUpperCase()}}
                        </span>
                </div>
                <div class="d-flex add-app-title justify-content-between align-items-center" *ngIf="!roles.length">
                        <span class="cursor-pointer app-selected">{{'No roles allotted to this user'}}
                        </span>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content class="roles-list pl-3 pr-3">
            <ng-container *ngIf="loader">
                <app-loader [loading]="loader"></app-loader>
            </ng-container>
            <div class="selected-role w-100 d-flex align-items-center mb-3 justify-content-between">
                <h3 class="ml-1">{{roleName.toUpperCase() |translate}}</h3>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap" *ngIf="roles.length">
                <mat-label class="form-group-title w-100">{{'Location'|translate}}
                    <hr class="col ml-3 w-75">
                </mat-label>
                <ng-container *ngFor="let element of allottedLocations">
                    <div class="d-flex flex-column justify-content-start">
<!--                        <p class="location-title">{{element.location_type  |translate}}</p>-->
                        <mat-chip-list class="chip-list mb-3 mt-3">
                            <mat-chip [matTooltip]="element.name" #tooltip="matTooltip" matTooltipClass="tooltip">{{element.name | trumcateText: 20}}</mat-chip>
                        </mat-chip-list>
                    </div>

                </ng-container>
                <ng-container *ngIf="!callingRoles.includes(roleName)">
                    <mat-label class="form-group-title w-100">{{'Permissions'|translate}}
                        <hr class="col ml-3 w-75">
                    </mat-label>
                    <div class="d-flex flex-wrap">
                        <div class="expansion-panel d-flex flex-wrap w-100" *ngFor="let group of permissions | keyvalue">
                            <ng-container *ngFor="let permission of group.value | keyvalue">
                                <label class="permission-name w-100 mt-3 mb-2">{{permission.key}}</label>
                                <ng-container *ngFor="let option of getValue(permission.value) ">
                                    <mat-checkbox class="single-checkbox d-flex min-w-[20%] mb-2"

                                                  [checked]="true" [disabled]="true">
                                        {{option.action}}
                                    </mat-checkbox>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="callingRoles.includes(roleName)">
                    <div class="d-flex flex-column">
                        <p class="location-title">{{'Primary Call Center'  |translate}}</p>
                        <mat-chip-list class="chip-list mb-3">
                            <mat-chip [matTooltip]="primaryCallCenter" #tooltip="matTooltip" matTooltipClass="tooltip">{{primaryCallCenter | trumcateText: 20}}</mat-chip>
                        </mat-chip-list>
                    </div>
                    <div class="d-flex flex-column">
                        <p class="location-title">{{' Call Center'  |translate}}</p>
                        <mat-chip-list class="chip-list mb-3">
                            <mat-chip [matTooltip]="callCenter" #tooltip="matTooltip" matTooltipClass="tooltip">{{callCenter | trumcateText: 20}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </ng-container>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
