<div class="container-md index-container mt-3">
    <!-- Page title -->
    <div class="title mb-3 d-flex justify-content-between flex-wrap align-items-center">
        <h3 class="d-flex align-items-center">
            <mat-icon class="title-icon mr-5" svgIcon="index-title-icon" matPrefix></mat-icon>
            {{ 'User Management' | translate }}
        </h3>
        <!--  ###########  -->
        <div class="d-flex">
            <mat-icon class="download-icon" svgIcon="upload" (click)="uploadUsersDialog('upload')"></mat-icon>
            <mat-icon class="download-icon " svgIcon="download" (click)="openDialog()"></mat-icon>
            <!--   Add Dip rules Button -->
            <button class="create-button rule-button"
                    [routerLink]="'/index/rule-based-access-management'">{{ 'Rule based Access Management '|translate }}
            </button>
            <button class="create-button mr-3"
                    (click)="uploadUsersDialog('delete')">{{ 'Delete Role '|translate }}
            </button>
            <!-- #########  -->
            <!--   Add user Button -->
            <button class="create-button" [routerLink]="'/index/user'">
                <mat-icon class="plus-icon mr-3" svgIcon="plus" matPrefix></mat-icon>
                {{ 'Add User'|translate }}
            </button>
            <!-- #########  -->
        </div>
    </div>
    <form [formGroup]=filterForm >
        <div class="client-apps mt-1 mb-1 d-flex justify-content-between flex-wrap align-items-start">
            <mat-button-toggle-group name="fontStyle" aria-label="client-apps" formControlName="client_app_ids"
                                     multiple>
                <mat-button-toggle *ngFor="let app of visibleApps" [value]="app.id">{{ app.name | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>

            <button type ="button" *ngIf="!showAllApps" (click)="toggleShowAllApps()" class="btn btn-link text-primary">See All Apps</button>
            <button type ="button" *ngIf="showAllApps" (click)="toggleShowAllApps()" class="btn btn-link text-primary">See Less</button>
        </div>

        <div *ngIf="showAllApps" class="client-apps mt-1 mb-1 d-flex justify-content-between flex-wrap">
            <mat-button-toggle-group name="fontStyle" aria-label="client-apps" formControlName="client_app_ids"
                                     multiple>
                <mat-button-toggle *ngFor="let app of remainingApps" [value]="app.id">{{ app.name | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="filter mb-1">
            <mat-accordion class="example-headers-align mat-elevation-z0 expansion">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ 'Filters'|translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- ######## Filter form Start ########-->
                    <div class="forms">
                        <div class="location-filter d-flex flex-wrap">
                            <div class="form-field mr-3 mb-3" style="margin-top: 2px">
                                <mat-label class="form-label mt-3 w-100 mb-3">{{ 'Filter By State' | translate }}
                                </mat-label>
                                <ng-select [items]="states" bindValue="id" bindLabel="name"
                                           placeholder="{{'Select State'}}" appearance="outline" class="form-field"
                                           [clearable]="false" aria-disabled="false" formControlName="state_id">
                                    <ng-template ng-option-tmp let-item="item"><label
                                            class="options" [matTooltip]="item.name">{{ item.name }}</label>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item"><label
                                            class="options" [matTooltip]="item.name">{{ item.name }}</label>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="w-100 flex-wrap d-flex">
                                <mat-label class="form-label w-100">{{ 'Filter By Level' | translate }}</mat-label>
                                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                                    <mat-label>{{ 'Level'| translate }}</mat-label>
                                    <mat-select formControlName="level" disableRipple>
                                        <mat-option *ngFor="let option of levels"
                                                    [value]="option.id">{{ option.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="filterForm.get('level').value">
                                    <div class="form-field mr-3 mb-3"
                                         *ngFor="let hierarchy of levelHierarchy; let i = index;"
                                         style="margin-top: 2px">
                                        <ng-select [items]="locationsDropdown[hierarchy.location_type]" bindValue="id"
                                                   placeholder="{{'Select ' + hierarchy.name}}" appearance="outline"
                                                   class="form-field"
                                                   bindLabel="name"
                                                   [disabled]="!locationsDropdown[hierarchy.location_type]"
                                                   (change)="getRequiredLocation(hierarchy.location_type, $event, hierarchy.required_location_type, i)"
                                                   [clearable]="false" [clearSearchOnAdd]="true"
                                                   [(ngModel)]="dropDowns[i]" [ngModelOptions]="{standalone: true}"
                                                   [searchFn]="utils.customSearchFn">
                                            <ng-template ng-option-tmp let-item="item"><label
                                                    class="options">{{ item.number ? item.number + ' - ' + item.name : item.name }}</label>
                                            </ng-template>
                                            <ng-template ng-label-tmp let-item="item"><label
                                                    class="options">{{ item.number ? item.number + ' - ' + item.name : item.name }}</label>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap">
                            <mat-label class="form-label w-100">{{ 'Filter By Calling Role' | translate }}</mat-label>
                            <mat-form-field appearance="outline" class="calling-form-field form-field"
                                            [style.FontSize.px]="11">
                                <mat-label>{{ 'Calling Role'| translate }}</mat-label>
                                <mat-select formControlName="calling_role">
                                    <mat-option *ngFor="let option of callingRole"
                                                [value]="option.value">{{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-button class="clear-button"
                                    (click)="clearFilters()">{{ 'Clear'|translate }}
                            </button>
                            <button mat-button class="search-button"
                                    (click)="searchUser()" [disabled]="isLoading">
                                <mat-icon class="icon" svgIcon="search" matPrefix></mat-icon>
                                {{ 'Search'|translate }}
                            </button>
                        </div>
                    </div>

                    <!--                    Not need for showing -->
                    <div class="filter-list d-flex flex-wrap mt-2">
                        <!--                        <p class="title">Locations : - </p>-->
                        <!--                        <p class="location-name" *ngIf="selectedLevel">{{selectedLevel}}</p>-->
                        <!--                        <p class="location-name" *ngIf="selectedStateName">{{selectedStateName}}</p>-->
                        <!--                        <p class="location-name" *ngIf="selectedParentLocationName">{{selectedParentLocationName}}</p>-->
                        <!--                        <p class="location-name" *ngIf="selectedLocationName">{{selectedLocationName}}</p>-->
                        <!--                        <p class="location-name" *ngIf="selectedRole">{{selectedRole}}</p>-->
                    </div>
                    <!--     ############ Filter Forms End ##########-->
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="position-relative">
            <div class="search-bar-user">
                <mat-form-field appearance="outline" class="search-field" style="width: 100%;" [style.fontSize.px]="14">
                    <input matInput type="search"
                           [placeholder]="'Search by ' + filterForm.get('query_type').value.split('_').join(' ') |translate"
                           formControlName="query">
                    <button type="button" matPrefix mat-icon-button [matMenuTriggerFor]="menu" aria-label="Clear">
                        <mat-icon class="search-icon">filter_list</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" formControlName="query_type">
                        <ng-container *ngFor="let queryType of queryTypes">
                        <span mat-menu-item
                              (click)="setQueryType(queryType.value)">{{ queryType.name }}</span>
                        </ng-container>
                    </mat-menu>
                    <mat-icon class="search-icon" matSuffix svgIcon="search-grey"></mat-icon>
                </mat-form-field>
            </div>

            <mat-tab-group class="user-tab" (selectedTabChange)="changeTab($event)" [selectedIndex]="selected"
                           dynamicHeight animationDuration="0ms">
                <mat-tab label="{{'Active'}}">
                    <ng-container *ngIf="!isLoading">
                        <app-user-table [users]="userList" [pageSize]="pageSize" [length]="length"
                                        [limit]="pageSize" [offset]="offset" [pageJumpSize]="pageJumpSize"
                                        [isLoading]="countLoader"
                                        [pageNumber]="pageNumber" [pageSizeOptions]="pageSizeOptions"
                                        (userAction)="getUserList()" (pagination)="paginationClicked($event)"
                                        (pageSizeChanged)="pageSizeChanged($event)" (userCount)="userListCount()"></app-user-table>
                    </ng-container>
                    <ng-container *ngIf="isLoading">
                        <ngx-skeleton-loader
                                [theme]="{ 'border-radius': '5px', height: '70px','background-color': '#f8f4f4', border: '1px solid white' }"
                                animation="true" count="10"></ngx-skeleton-loader>
                    </ng-container>

                </mat-tab>
                <mat-tab label="{{'Disabled'}}">
                    <ng-container *ngIf="!isLoading">
                        <app-user-table [users]="userList" [pageSize]="pageSize" [length]="length"
                                        [limit]="pageSize" [offset]="offset" [pageJumpSize]="pageJumpSize"
                                        [isLoading]="countLoader"
                                        [pageNumber]="pageNumber" [pageSizeOptions]="pageSizeOptions"
                                        (userAction)="getUserList()" (pagination)="paginationClicked($event)"
                                        (pageSizeChanged)="pageSizeChanged($event)" (userCount)="userListCount()"></app-user-table>
                    </ng-container>
                    <ng-container *ngIf="isLoading">
                        <ngx-skeleton-loader
                                [theme]="{ 'border-radius': '5px', height: '70px','background-color': '#f3dddd', border: '1px solid white' }"
                                animation="true" count="10"></ngx-skeleton-loader>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>

    </form>

</div>
