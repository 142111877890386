import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private snackBar: MatSnackBar) { }


  closableSnackBar(message, duration = 5000) {
    this.snackBar.open(message, 'Okay', {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });
  }
  errorSnackBar(message, duration = 5000) {
    this.snackBar.open(message, 'Okay', {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-snackbar'
    });
  }
}
