import {Component, OnInit} from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {UtilsService} from "../../services/utils.service";
import {MessageService} from "../../services/message.service";

@Component({
    selector: 'app-view-roles',
    templateUrl: './view-roles.component.html',
    styleUrls: ['./view-roles.component.css']
})
export class ViewRolesComponent implements OnInit {
    currentChoice: string;
    roles = []
    roleName = ''
    permissions = []
    allottedLocations = []
    isLoading: boolean = false;
    loader: boolean = false;
    callingRoles = ['agent', 'team_lead', 'call_center_manager']
    callCenter = ''
    primaryCallCenter = ''

    constructor(private apiService: RestApiService, private utils: UtilsService, private messageService: MessageService) {
    }

    ngOnInit() {
        this.getUserRoles()
    }

    // ##<-------- Set Active App in Nav Bar ------->
    getActiveRoleClass(choice): any {
        if (this.currentChoice == choice)
            return "active";
        else
            return "not";
    }

    setActiveRole(choice: string): void {
        this.currentChoice = choice;
        this.roleName = choice;
        this.getUserLocationsPermissions(choice);
    }

    getUserRoles(): void {
        this.isLoading = true;
        this.apiService.userRoles(this.utils.userId, '').subscribe((response: any) => {
            this.roles = response.data.roles;
            this.isLoading = false;
            this.roleName = this.roles[0].name;
            this.currentChoice = this.roleName;
            if (this.roleName) {
                this.setActiveRole(this.roleName)
            }
        }, error => {
            this.messageService.errorSnackBar(error.message);
            this.isLoading = false;
        });
    }

    getUserLocationsPermissions(role): void {
        this.loader = true;
        this.apiService.userRoles(this.utils.userId, role).subscribe((response: any) => {
            this.permissions = response.data.permissions;
            this.allottedLocations = response.data.location;
            this.callCenter = response.data.cc;
            this.primaryCallCenter = response.data.primary_call_center;
            this.loader = false;
        }, error => {
            this.messageService.errorSnackBar(error.message);
            this.loader = false;
        });
    }

    getValue(items: any): any {
        return items;
    }
}
