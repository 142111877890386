import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {RestApiService} from "../services/rest-api.service";
import {Router} from "@angular/router";
import {UtilsService} from "../services/utils.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DeviceDetectorService} from 'ngx-device-detector';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import * as Constant from '../constant';
import {MessageService} from "../services/message.service";
import {debounceTime} from "rxjs";
import {LoaderService} from "../services/loader.service";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    constructor(private router: Router, private restService: RestApiService,
                private translate: TranslateService, public utils: UtilsService, private http: HttpClient, private formBuilder: FormBuilder,
                private dialogRef: MatDialog, public snackBar: MatSnackBar, private deviceService: DeviceDetectorService, private messageService: MessageService,
                private loaderService: LoaderService) {
    }

    loginForm: FormGroup = new FormGroup({});
    hide = true;
    otpToken = '';
    showOTPButton = true;
    @ViewChild('emailInput') emailInput: any;
    loginButtonText = 'Login';
    mobile = false;
    ipAddress;
    otp: boolean;
    // <-------- configuration variables for ng otp input ------------------>
    config = {
        length: 6,
        allowNumbersOnly: true,
        isPasswordInput: true,
        disableAutoFocus: false,
        inputStyles: {
            border: '1px solid #B1B1B1',
            borderRadius: '1px',
            width: '60px',
            height: '40px',

        }
    };

    disabled = false;
    buttonDisable = false;
    time = 0;
    display;
    interval;


// <--------------------- Function For Starting Otp CountDown Timer-------------------->
    startTimer() {
        this.interval = setInterval(() => {
            this.otp = false;
            if (this.time > 0) {
                this.time--;
                this.disabled = false;
            } else {
                this.time = 60;
            }
            if (this.time === 0) {
                clearInterval(this.interval);
                this.disabled = true;
            }
            this.display = this.transform(this.time);
        }, 1000);
    }

// <-----------------------Transforming the time value--------------------->
    transform(value: number): string {
        // tslint:disable-next-line:variable-name
        const sec_num = value;
        const hours = Math.floor(sec_num / 3600);
        const minutes = Math.floor((sec_num - (hours * 120)) / 60);
        const seconds = sec_num - (hours * 120) - (minutes * 60);
        if (seconds < 10) {
            return '0' + minutes + ':' + '0' + seconds;
        } else {
            return '0' + minutes + ':' + seconds;
        }
    }

// <-----------------Resend Otp Function----------------->
    resend() {
        this.loaderService.show();
        this.otp = true;
        const identificationToken = {
            identification_token: this.otpToken
        };
        this.restService.resend_otp(identificationToken).subscribe(reply => {
            const response = reply as any;
            this.startTimer();
            this.loaderService.hide();
        }, error => {
            this.loaderService.hide();
            this.messageService.errorSnackBar(error);
        });
    }

    ngOnInit() {
        if (this.dialogRef) {
            this.dialogRef.closeAll();
        }
        console.log(window.screen.width);
        if (window.screen.width < 500) { // 768px portrait
            this.mobile = true;
        }
        this.loginForm = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.pattern(this.utils.emailPattern)]),
            password: new FormControl('', [Validators.required]),
            otp: new FormControl('', [Validators.required]),
        });
        this.restService.two_factor_enabled().subscribe(response => {
            this.loaderService.show();
            if (response && (response as any).data === true) {
                this.loaderService.hide();
                this.loginButtonText = 'Send OTP';
            }
        }, error => {
            this.loaderService.hide();
            this.messageService.errorSnackBar(error);
        });

        if (this.utils.isLoggedIn()) {
            this.router.navigate(['/index']);
        }
        this.http.get('https://api.ipify.org/?format=json').subscribe((response: any) => {
            this.ipAddress = response.ip;
        });
        this.onFormChanges();
    }

    onFormChanges(): void {
        this.loginForm.get('email').valueChanges.pipe(debounceTime(1000)).subscribe(value => {
            if (this.loginForm.get('email').valid) {

            }
        });
    }

    otpClicked(): void {
        this.buttonDisable = true;
        let credentials = {};
        this.buttonDisable = true;
        if (this.loginForm.get('password').valid && this.loginForm.get('email').valid) {
            credentials = {
                email: this.loginForm.get('email').value,
                password: this.loginForm.get('password').value,
                sso_portal: true
            };
        }
        this.restService.login(credentials).subscribe((data: any) => {
            this.buttonDisable = false;
            if (data && (data).auth_token) {
                this.loginSuccessful((data));
            } else {
                this.buttonDisable = false;
                this.startTimer();
                this.otpToken = data.identification_token;
                this.showOTPButton = false;
            }
        }, error => {
            this.buttonDisable = false;
            this.messageService.errorSnackBar(error, 6000);
        });
    }

    onOtpChange(otp: any) {
        this.loginForm.get('otp').setValue(otp);
    }


// <----------------function for submitting the the otp -------------------->
    loginClicked(): void {
        if (this.loginForm.get('otp').value.length !== 6) {
            this.snackBar.open('Please Enter A Valid OTP', '', {
                duration: 6000
            });
            return;
        }
        const otp = {
            identification_token: this.otpToken,
            otp: this.loginForm.get('otp').value,
            sso_portal: true
        };
        this.restService.submit_otp(otp).subscribe(response => {
            const res = response as any;
            this.buttonDisable = false;
            if (this.utils.isPortalAllowed(response.user.permissions, 'Read')) {
                this.loginSuccessful(res);
                this.router.navigate(['index']);
            } else {
                localStorage.setItem(Constant.AUTH_STATUS, 'false');
                this.router.navigate(['error'], {
                    queryParams: {
                        error: 401
                    }
                });
            }
            // this.userSessionInfo(res);
        }, error => {
            this.buttonDisable = false;
            if (error) {
                if (error === 'Your account has been deactivated due to incorrect login attempts. Please contact to your Admin') {
                    this.showOTPButton = true;
                    this.loginForm.get('password').setValue('');
                    this.loginForm.get('otp').setValue('');
                }
                this.messageService.errorSnackBar(error, 5000)
            } else {
                this.messageService.errorSnackBar('Unknown error found', 5000);
            }
        });
    }

    loginSuccessful(loginData: any) {
        localStorage.setItem(Constant.AUTH_STATUS, 'true');
        localStorage.setItem(Constant.AUTH_TOKEN, loginData.auth_token);
        localStorage.setItem(Constant.USEREMAIL, loginData.user.email);
        localStorage.setItem(Constant.USERPHONE, loginData.user.phone);
        localStorage.setItem(Constant.PERMISSIONS, JSON.stringify(loginData.user.permissions));
        localStorage.setItem(Constant.USERROLE, loginData.user.role);
        localStorage.setItem(Constant.USERNAME, loginData.user.name);
        localStorage.setItem(Constant.USER_ID, loginData.user.id)
        localStorage.setItem(Constant.USER_ROLE_ID, loginData.role_id);
        localStorage.setItem(Constant.COUNTRY_STATE, JSON.stringify(loginData.user.countryState));
        localStorage.setItem(Constant.STATES, JSON.stringify(loginData.user.assignedStates));
        localStorage.setItem(Constant.SYSTEM_LANGUAGE, loginData.user.locale);
        localStorage.setItem(Constant.MANAGER_LOCATION, loginData.user.managerLocation);
        localStorage.setItem(Constant.MANAGER_TYPE, loginData.user.managerType);
        localStorage.setItem(Constant.IS_MANAGER_ROLE, loginData.user.isManagerRole);
        localStorage.setItem(Constant.ALL_ROLES, JSON.stringify(loginData.user.allRoles));
        localStorage.setItem(Constant.USER_IMAGE, JSON.stringify(loginData.user.photo));
    }

// <------------Getting the session ingo of the user ------------------>
//   userSessionInfo(userId) {
//     const data = {
//       user_id: userId,
//       device_data: this.deviceService.getDeviceInfo(),
//       ip_address: this.ipAddress
//     };
//     this.restService.userSessionInfo(data).subscribe((response: any) => {
//       const res = response as any;
//       console.log(res);
//       },
//       error => {
//         this.messageService.errorSnackBar(error.error.message, 6000);
//       });
//   }
}
