import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {RestApiService} from "../services/rest-api.service";
import {UtilsService} from "../services/utils.service";
import {MessageService} from "../services/message.service";
import {RuleAccessPopupComponent} from "../shared/rule-access-popup/rule-access-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {XlsxDownloadService} from "../services/xlsx-download.service";
import {DownloadOptionsComponent} from "../user-management/shared/download-options/download-options.component";

@Component({
    selector: 'app-dip-rules',
    templateUrl: './dip-rules.component.html',
    styleUrls: ['./dip-rules.component.css']
})
export class DipRulesComponent implements OnInit {
    filters: FormGroup = new FormGroup({});
    query = new FormControl('');
    states = [];
    dataLevel = [];
    dataUnit = [];
    subUnit = [];
    isLoading = false;
    dataType = [];
    designation = [];
    headerItem = [{name: 'State'}, {name: 'Data Type'}, {name: 'Level'}, {name: 'Unit'}, {name: 'Sub-unit'}, {name: 'Designation'},]
    accesses: any;
    downloadButton = false;
    offset: number = 0;
    limit: number = 5;
    pageSize: number = 5;
    pageNumber: number = 1;
    pageJumpSize: 10;
    pageSizeOptions: number[] = [5, 10, 15, 25];
    length: any;
    show = {};
    clientApps = []
    appClientId: string;
    areaType: string;
    selectAll: boolean = false;
    selectedRules = [];
    allRules = false;

    constructor(private formBuilder: FormBuilder, private apiService: RestApiService, private utils: UtilsService,
                private messageService: MessageService, private dialog: MatDialog, private router: Router,
                private xlsxDownloadService: XlsxDownloadService, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.getClientApps()
        this.filters = this.formBuilder.group({
            client_app_id: new FormControl(''),
            state_id: new FormControl(''),
            data_level_id: new FormControl(''),
            data_unit_id: new FormControl(''),
            data_type_id: new FormControl(''),
            sub_unit_id: new FormControl(''),
            designation_id: new FormControl(''),
        });
        this.getCountryState();
        this.getClientApps()
        this.onFormChanges();
        this.checkUrlParams();

    }

    onFormChanges(): void {
        this.filters.get('client_app_id').valueChanges.subscribe(value => {
            this.filters.get('data_type_id').setValue('')
            this.appClientId = ''
            if (value) {
                this.setClientId(this.clientApps, value);
            }
        });
        this.filters.get('state_id').valueChanges.subscribe(value => {
            this.filters.get('data_type_id').setValue('')
            this.dataType = [];
            if (value) {
                this.getDataType();
            }
        });
        this.filters.get('data_level_id').valueChanges.subscribe(value => {
            this.filters.get('data_unit_id').setValue('')
            this.dataUnit = [];
            if (value) {
                this.dataLevel.forEach(level => {
                    if (level.id === value) {
                        this.areaType = level.level_class
                    }
                })
                this.getUnits(value);
            }
        });
        this.filters.get('data_type_id').valueChanges.subscribe(value => {
            this.filters.get('data_level_id').setValue('')
            this.dataUnit = [];
            if (value) {
                this.getLevel();
            }
        });
        this.filters.get('data_unit_id').valueChanges.subscribe(value => {
            this.filters.get('sub_unit_id').setValue('')
            this.subUnit = [];
            if (value) {
                this.getSubUnits(value)
            }
        });
        this.filters.get('sub_unit_id').valueChanges.subscribe(value => {
            this.filters.get('designation_id').setValue('')
            this.designation = [];
            if (value) {
                this.getDesignations(value);
            }
        });
        this.filters.get('designation_id').valueChanges.subscribe(value => {
            if (value) {
            }
        });
    }

    getCountryState(): void {
        this.apiService.allottedCountryStates().subscribe((response: any) => {
            this.states = response.data;
        }, error => {
            this.messageService.errorSnackBar(error)
        });
    }

    getDataType(): void {
        this.apiService.getDataTypes().subscribe((response: any) => {
            this.dataType = response.data;
        }, error => {
            this.messageService.errorSnackBar(error.message)
        });
    }

    getDipRules(): void {
        this.isLoading = true;
        this.apiService.getDipRules(this.filters.get('client_app_id').value, this.filters.get('state_id').value, this.filters.get('data_type_id').value, this.filters.get('data_level_id').value,
            this.filters.get('data_unit_id').value, this.filters.get('sub_unit_id').value, this.filters.get('designation_id').value, this.pageSize, this.offset).subscribe((response: any) => {
            if (response) {
                this.accesses = response.rules;
                this.length = response.length;
                this.isLoading = false;
                this.selectAllRules();
            }
            this.valueToRoutes();
        }, error => {
            this.messageService.errorSnackBar(error);
            this.isLoading = false;
        });
    }

    openDialog(rule): void {
        let message: string
        message = 'Are you sure you want to delete this rule?'
        const dialogRef = this.dialog.open(RuleAccessPopupComponent, {
            disableClose: false,
            width: '350px',
            data: {message: message}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.apiService.deleteDipRules(rule.id).subscribe((response: any) => {
                    if (response) {
                        this.messageService.closableSnackBar('Rule has been deleted')
                        this.getDipRules();
                    }
                }, error => {
                    this.messageService.errorSnackBar(error);
                });
            }
        });
    }

    editRule(rule): void {
        let clientApp = rule.client_app.id
        let stateId = rule.country_state.id
        let typeId = rule.data_type.id
        let levelId = rule.data_level.id
        let unitId = rule.data_unit.id
        let subunitId = rule.sub_unit.id
        let designationId = rule.designation.id
        let levelName = rule.data_level.name
        let ruleId = rule.id
        let clientAppId = rule.client_app.client_id
        this.router.navigate(['/index/rule-based-access-management/create-rules'], {
            queryParams: {
                clientApp: clientApp,
                rule: ruleId,
                state: stateId,
                type: typeId,
                level: levelId,
                unit: unitId,
                subunit: subunitId,
                designation: designationId,
                levelName: levelName,
                clientId: clientAppId
            }
        }).then();
    }

    valueToRoutes(): any {
        this.router.navigate([], {
            queryParams: {
                client_app_id: this.filters.get('client_app_id').value,
                state: this.filters.get('state_id').value,
                type_id: this.filters.get('data_type_id').value,
                data_level_id: this.filters.get('data_level_id').value,
                unit_id: this.filters.get('data_unit_id').value,
                sub_unit_id: this.filters.get('sub_unit_id').value,
                designation_id: this.filters.get('designation_id').value,
            },
            queryParamsHandling: 'merge',
        });
    }

    checkUrlParams(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.filters.get('client_app_id').setValue(params['client_app_id'] ? parseInt(params['client_app_id']) : '');
            this.filters.get('state_id').setValue(params['state'] ? parseInt(params['state']) : '');
            this.filters.get('data_type_id').setValue(params['type_id'] ? parseInt(params['type_id']) : '');
            this.filters.get('data_level_id').setValue(params['data_level_id'] ? parseInt(params['data_level_id']) : '');
            this.filters.get('data_unit_id').setValue(params['unit_id'] ? parseInt(params['unit_id']) : '');
            this.filters.get('sub_unit_id').setValue(params['sub_unit_id'] ? parseInt(params['sub_unit_id']) : '');
            this.filters.get('designation_id').setValue(params['designation_id'] ? parseInt(params['designation_id']) : '');
        });
        this.getDipRules();
    }

    clear(): void {
        this.filters.get('client_app_id').setValue('');
        this.filters.get('state_id').setValue('');
        this.dataLevel = [];
        this.appClientId = '';
        this.selectedRules = [];
        this.selectAll = false;
        this.allRules = false;
        this.getDipRules();
    }

    pageChanged(event): PageEvent {
        this.pageNumber = event;
        this.offset = event === 0 ? 0 : (event * this.pageSize - this.pageSize);
        this.getDipRules();
        this.selectAll = false;
        this.allRules = false;
        return event;
    }

    getUnits(value) {
        const data = {
            type: 'Unit',
            country_state_id: this.filters.get('state_id').value,
            area_type: this.areaType,
            data_type: this.filters.get('data_type_id').value,
            data_level: value,
            unit_id: null,
            sub_unit_id: null
        };
        this.apiService.getFilterOptions(data).subscribe(reply => {
            const response = reply as any;
            this.dataUnit = response.data;
        }, error => {
            this.messageService.errorSnackBar(error.error.message);
        });
    }

    getSubUnits(dataUnit) {
        const data = {
            type: 'SubUnit',
            country_state_id: this.filters.get('state_id').value,
            area_type: this.areaType,
            data_type: this.filters.get('data_type_id').value,
            data_level: this.filters.get('data_level_id').value,
            unit_id: dataUnit,
            sub_unit_id: null
        };
        this.apiService.getFilterOptions(data).subscribe((reply: any) => {
            this.subUnit = reply.data;
        }, error => {
            this.messageService.errorSnackBar(error.message,);
        });
    }

    getClientApps(): any {
        this.apiService.clientApps().subscribe(response => {
            const res = response as any;
            this.clientApps = res.data;
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
        });
    }


    getDesignations(subUnit) {
        const data = {
            type: 'Designation',
            country_state_id: this.filters.get('state_id').value,
            area_type: this.areaType,
            data_type: this.filters.get('data_type_id').value,
            data_level: this.filters.get('data_level_id').value,
            unit_id: this.filters.get('data_unit_id').value,
            sub_unit_id: subUnit
        };
        this.apiService.getFilterOptions(data).subscribe(reply => {
            const response = reply as any;
            this.designation = response.data;
        }, error => {
            this.messageService.errorSnackBar(error.error.message);
        });
    }

    getLevel(): void {
        if (!this.appClientId) {
            if (!this.clientApps.length) {
                this.apiService.clientApps().subscribe((response) => {
                    this.setClientId(response.data, this.filters.get('client_app_id').value)
                });
            }
        } else {
            this.appDataLevel();
        }
    }
    appDataLevel() {
        this.apiService.dataLevel(this.appClientId).subscribe((res: any) => {
            this.dataLevel = res.data;
        }, error => {
            this.messageService.errorSnackBar(error);
        });
    }

    paginationClicked(event): PageEvent {
        this.pageSize = event;
        this.pageNumber = 1;
        this.offset = (this.pageNumber - 1) * this.pageSize;
        this.limit = this.pageSize
        this.getDipRules();
        this.selectAll = false;
        this.allRules = false;
        return event;
    }

    toggle(index): void {
        this.show[index] = !this.show[index]
    }

    setClientId(clientApp, value): void {
        clientApp.forEach(app => {
            if (app.id === value) {
                this.appClientId = app.client_id;
            }
        });
        if (!this.dataLevel.length) {
            this.getLevel()
        }
    }

    downloadCreatedRules(): void {
        const header = ['Rule ID', 'Client App', 'Total Users', 'Total User Affected', 'State', 'Data Type', 'Level', 'Unit', 'Sub-unit', 'Designation', 'Permissions'];
        const data = []
        this.apiService.getDipRules(this.filters.get('client_app_id').value, this.filters.get('state_id').value, this.filters.get('data_type_id').value, this.filters.get('data_level_id').value,
            this.filters.get('data_unit_id').value, this.filters.get('sub_unit_id').value, this.filters.get('designation_id').value, this.length, 0).subscribe((response: any) => {
           response.rules.forEach((rule: any) => {
               const permissions = Object.keys(rule.permissions);
               const permissionValues = permissions.map(permission => {
                   if (permission === 'name') {
                       const values = rule.permissions[permission];
                       if (Array.isArray(values)) {
                           return `[${values.map(obj => JSON.stringify(obj)).join(', ')}]`;
                       } else {
                           return `[${JSON.stringify(values)}]`;
                       }
                   } else {
                       return ''
                   }
               });
               data.push([rule.id, rule.client_app.name, rule.total_users, rule.total_users_affected,rule.country_state.name, rule.data_type.name, rule.data_level.name, rule.data_unit.name,
                   rule.sub_unit.name, rule.designation.name, permissionValues.join('')])
           })
            this.downloadXlsx(header, data, 'created_rules')
        }, error => {
            this.messageService.errorSnackBar(error);
            this.isLoading = false;
        });

    }
    downloadXlsx(header, data, name): any {
        const headers = header;
        const xlsxData = data;
        const filename = name + `-${(new Date()).toString().substring(0, 24)}.xlsx`;
        this.xlsxDownloadService.download(headers, xlsxData, filename);
    }

    getObjectKeysLength(obj: any): number {
        let totalLength = 0;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                totalLength += obj[key].length;
            }
        }
        return totalLength;
    }

    ruleUserDownloadDialog(cardItem): void {
        const dialogRef = this.dialog.open(DownloadOptionsComponent, {
            width: '550px',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== 'false') {
                this.downloadUserList(result, cardItem);
            }
        });
    }

    downloadUserList(email: any, rule: { client_app: { id: any; }; id: string; }){
        let onlyRbs = true
        this.apiService.userListDownload(email, '', '', '', '', '', rule.client_app.id,
            '', '', '' , '', '', rule.id, onlyRbs).subscribe((response: any) => {
            if (response) {
                this.messageService.closableSnackBar(response.message, 5000);
            }
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
            this.isLoading = false;
        });
    }

    getFilterRules(): void{
        this.pageNumber = 1;
        this.offset = (this.pageNumber - 1) * this.pageSize;
        this.limit = this.pageSize
        this.getDipRules();
        this.selectedRules = [];
        this.selectAll = false;
        this.allRules = false;
    }

    deletePermissionRules(){

        let data = this.filters.value;
        let message = '';
        if (this.allRules){
            message = `Are you sure you want to delete all ${this.length} rules`;
        }else if (this.selectAll || this.selectedRules.length > 0 ) {
            message = `Are you sure want to delete all rules with id's - ${this.selectedRules.join(', ')}`;
        }
        const dialogRef = this.dialog.open(RuleAccessPopupComponent, {
            disableClose: false,
            width: '350px',
            data: {message: message}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                if (this.allRules) {data.all_rules = this.allRules;}
                if (this.selectedRules.length > 0 ) { data.selected_rules = this.selectedRules;}
                this.apiService.deletePermissionRules(data).subscribe((response: any) => {
                    if (response) {
                        this.messageService.closableSnackBar('Rules has been deleted.')
                        this.selectedRules = [];
                        this.selectAll = false;
                        this.allRules = false;
                        this.getDipRules();
                        this.isLoading= false;
                    }
                }, error => {
                    this.isLoading= false;
                    this.messageService.errorSnackBar(error);
                });
            }
        });
    }

    toggleSelectAll(): void {
        this.selectedRules = [];
        this.accesses.forEach(access => {
            access.selected = this.selectAll;
            if (this.selectAll) {
                this.selectedRules.push(access.id);
            }
        });
    }

    onCheckboxChange(id: number, selected: boolean): void {
        if (selected) {
            if (!this.selectedRules.includes(id)) {
                this.selectedRules.push(id);
            }
        } else {
            this.selectedRules = this.selectedRules.filter(ruleId => ruleId !== id);
        }

        // Update the selectAll checkbox status
        this.selectAll = this.accesses.every(access => access.selected);
    }

    deleteSelectedRules() {
        const selectedRules = this.accesses.filter(item => item.selected);
        this.deletePermissionRules();
    }

    selectAllRules() {
        if (this.selectedRules.length > 0) {
            this.accesses.forEach(access => {
                if (this.selectedRules.includes(access.id)) {
                    access.selected = true;
                }
            });
        }
    }

    deleteAllRules() {
        this.allRules = !this.allRules;
        this.selectedRules = [];
        if (!this.allRules) {
            this.selectAll = false;
            this.accesses.forEach(access => { access.selected = this.selectAll;});
        }
    }

}
