import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class XlsxDownloadService {

  constructor() { }
  download(headers, data, filename): any {
    const xlsxData = [headers].concat(data);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(xlsxData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, filename);
  }
}
