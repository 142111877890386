<h1 mat-dialog-title class="title">{{'Confirmation'}}</h1>
<div class="d-flex justify-content-between flex-wrap">
    <h5 class="confirmation">{{message}}</h5>
</div>

<div>
    <div class="button-container d-flex mt-5 justify-content-end">
        <button mat-button class="cancel-button button" (click)="confirmation(false)">{{'Cancel'|translate}}</button>
        <button mat-raised-button class="confirm-button button ml-3"
                (click)="confirmation(true)">{{'Yes' |translate}}</button>
    </div>
</div>
