import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import * as Constant from './constant'
import {UtilsService} from "./services/utils.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, public utils: UtilsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.utils.isAuthorized = true;
    if (!route.url.length) {
      return true;
    }
    if (localStorage.getItem(Constant.AUTH_STATUS) === 'true') {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }


}
