<mat-toolbar class="d-flex w-100 navbar app-header">
  <mat-icon class="navbar-header-icon" svgIcon="logo" [routerLink]="'/index'"></mat-icon>
  <span class="navbar-header-title" [routerLink]="'/index'">भारतीय जनता पार्टी</span>
    <span class="spacer"></span>
    <div class="user-profile-container">
        <span class="user-name"> {{userName | translate}} </span>
        <button mat-icon-button [matMenuTriggerFor]="rootMenu">
            <mat-icon class="user-profile-details"> keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #rootMenu="matMenu">

            <button mat-menu-item [routerLink]="['/profile']">
                <mat-icon>face</mat-icon>
                {{ 'Profile' | translate}}
            </button>

            <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Sign Out</span>
            </button>
        </mat-menu>
    </div>

</mat-toolbar>
