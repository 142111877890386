import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import * as Constant from '../constant';

@Injectable({
    providedIn: 'root'
})
export class RestApiService {
    options: any;
    apiUrl = environment.baseUrl;
    customApiUrl = environment.apiUrl;
    saralUrl = environment.saralUrl;

    constructor(private http: HttpClient) {
    }

    authHttpOptions(showInterceptorLoader: boolean = true) {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-role_id': (localStorage.getItem(Constant.USER_ROLE_ID) || ''),
                Authorization: localStorage.getItem('authToken') ? localStorage.getItem('authToken') : '',
                Accept: 'application/json',
                'x-showLoader': showInterceptorLoader.toString()
            })
        };
    }

    two_factor_enabled(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(this.apiUrl + 'two_factor_enabled?render_json=true', httpOptions);
    }

    login(credentials): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + 'login', JSON.stringify(credentials), httpOptions);
    }

    submit_otp(otp): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + 'submit_otp', JSON.stringify(otp), httpOptions);
    }

    resend_otp(otpToken): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + 'resend_otp', JSON.stringify(otpToken), httpOptions);
    }


    userSessionInfo(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + 'session_information', JSON.stringify(data), httpOptions);
    }

    logout(): Observable<any> {
        return this.http.get(this.apiUrl + 'logout', this.authHttpOptions());
    }

    clientApps(userId = null): Observable<any> {
        return this.http.get(this.customApiUrl + 'client_apps?user_id=' + userId, this.authHttpOptions())
    }

    userRoles(userId, role): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/user_roles?user_id=' + userId + '&role=' + role, this.authHttpOptions())
    }

    deleteClientApps(userId, clientAppId): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/delete?user_id=' + userId + '&client_id=' + clientAppId, this.authHttpOptions())
    }


    userClientApps(userId): Observable<any> {
        return this.http.get(this.customApiUrl + 'user_client_apps?user_id=' + userId, this.authHttpOptions())
    }

    dataLevelHierarchy(dataLevelId): Observable<any> {
        return this.http.get(this.customApiUrl + 'level/hierarchy?data_level_id=' + dataLevelId, this.authHttpOptions())
    }

    userLocations(userId, clientId): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/user_tags?user_id=' + userId + '&client_id=' + clientId, this.authHttpOptions())
    }

    userTagDetails(userTagId, clientId): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/user_tag_details?user_tag_id=' + userTagId + '&client_app_id=' + clientId, this.authHttpOptions())
    }

    dataLevels(): Observable<any> {
        return this.http.get(this.customApiUrl + 'data_levels', this.authHttpOptions())
    }

    userList(data: {
        locationType: string; locationId: any; callingRole: any; offset: number;
        query: any; clientAppIds: any; dataLevelId: any; disabled: boolean;
        limit: number; stateId: string; queryType: any;  isCount: boolean;
    }, extraHeaders: { showLoader: boolean } = {showLoader: true}): Observable<any> {
        return this.http.get(this.customApiUrl + 'users?location_type=' + data.locationType + '&location_id=' + data.locationId + '&calling_role=' + data.callingRole +
            '&query=' + data.query + '&client_app_ids=' + data.clientAppIds + '&data_level_id=' + data.dataLevelId + '&disabled=' + data.disabled + '&limit=' + data.limit + '&offset='
            + data.offset + '&state_id=' + data.stateId + '&query_type=' + data.queryType + '&is_count=' + data.isCount, this.authHttpOptions())
    }

    userListDownload(email, locationType, disabled, locationId, callingRole, query, clientAppIds, dataLevelId, limit, offset, state_id, queryType, ruleId = '', onlyRbs = false): Observable<any> {
        return this.http.get(this.customApiUrl + 'download_users?email=' + email + '&location_type=' + locationType + '&location_id=' + locationId +
            '&calling_role=' + callingRole + '&query=' + query + '&client_app_ids=' + clientAppIds + '&data_level_id=' + dataLevelId + '&disabled=' + disabled + '&state_id=' + state_id +
            '&query_type=' + queryType + '&rule_id=' + ruleId + '&only_rbs=' + onlyRbs, this.authHttpOptions())
    }

    toggleActivateUser(data: { userId }, extraHeaders: { showLoader: boolean } = {showLoader: true}): Observable<any> {
        return this.http.get(this.customApiUrl + 'activate_deactivate/user?user_id=' + data.userId, this.authHttpOptions(extraHeaders.showLoader))
    }

    toggleUser(userId): Observable<any> {
        return this.http.get(this.customApiUrl + 'enable_disable/user?user_id=' + userId, this.authHttpOptions())
    }

    toggleTeamAddition(userId): Observable<any> {
        return this.http.get(this.customApiUrl + 'toggle_team_addition/user?user_id=' + userId, this.authHttpOptions())
    }

    searchUser(userId, phoneNumber): Observable<any> {
        return this.http.get(this.customApiUrl + 'get/user?user_id=' + userId + '&phone_number=' + phoneNumber, this.authHttpOptions())
    }

    createUser(data): Observable<any> {
        return this.http.post(this.customApiUrl + 'update/user', data, this.authHttpOptions())
    }

    setClientApp(data): Observable<any> {
        return this.http.post(this.customApiUrl + 'set/client_app', data, this.authHttpOptions())
    }

    allottedCountryStates(): Observable<any> {
        return this.http.get(this.apiUrl + 'data/get_allotted_country_states/all', this.authHttpOptions())
    }


    dataLevel(clientAppId): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/data_levels?client_id=' + clientAppId, this.authHttpOptions())
    }

    requiredLocation(locationType, locationId, requiredLocationType, acId, zilaId): Observable<any> {
        return this.http.get(this.apiUrl + 'data/required_locations?location_type=' + locationType + '&location_id=' + locationId +
            '&required_location_type=' + requiredLocationType + '&ac_id=' + acId + '&zila_id=' + zilaId, this.authHttpOptions())
    }

    appPermission(clientAppId, dataLevelId, userId, userTagId): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/permissions?client_id=' + clientAppId + '&data_level_id=' + dataLevelId + '&user_id=' + userId +
            '&user_tag_id=' + userTagId, this.authHttpOptions())
    }

    updateUserTags(data): Observable<any> {
        return this.http.post(this.customApiUrl + 'update/tags', data, this.authHttpOptions())
    }

    toggleClientApp(data): Observable<any> {
        return this.http.post(this.customApiUrl + 'app/toggle_disable', data, {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem(Constant.AUTH_TOKEN) ? localStorage.getItem(Constant.AUTH_TOKEN) : '',
                Accept: 'application/json'
            })
        });
    }

    deleteUserTag(userTagId: string, appId: string): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/delete_user_tag?user_tag_id=' + userTagId + '&client_app_id=' + appId, this.authHttpOptions())
    }

    toggleUserTag(data): Observable<any> {
        return this.http.post(this.customApiUrl + 'app/toggle_user_tag', data, {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem(Constant.AUTH_TOKEN) ? localStorage.getItem(Constant.AUTH_TOKEN) : '',
                Accept: 'application/json'
            })
        });
    }

    viewSsoDetails(client_id, userId): Observable<any> {
        return this.http.get(this.customApiUrl + 'app/user_tag_info?client_id=' + client_id + '&user_id=' + userId, this.authHttpOptions())
    }

    uploadPhoto(data): Observable<any> {
        return this.http.post(this.saralUrl + 'custom_member_forms/add_file', data, {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem(Constant.AUTH_TOKEN) ? localStorage.getItem(Constant.AUTH_TOKEN) : '',
                Accept: 'application/json'
            })
        });
    }

    uploadSsoUsers(data): Observable<any> {
        return this.http.post(this.saralUrl + 'user_management/upload_sso_users', data, {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem(Constant.AUTH_TOKEN) ? localStorage.getItem(Constant.AUTH_TOKEN) : '',
                Accept: 'application/json'
            })
        });
    }

    uploadRoleDeletion(data): Observable<any> {
        return this.http.post(this.customApiUrl + 'app/delete_user_roles', data, {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem(Constant.AUTH_TOKEN) ? localStorage.getItem(Constant.AUTH_TOKEN) : '',
                Accept: 'application/json'
            })
        });
    }

    getDataTypes(): Observable<any> {
        return this.http.get(this.apiUrl + 'data/data_type', this.authHttpOptions());
    }

    getFilterOptions(data): Observable<any> {
        return this.http.post(this.apiUrl + 'dashboard/get_filter_options', data, {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem(Constant.AUTH_TOKEN) ? localStorage.getItem(Constant.AUTH_TOKEN) : '',
                Accept: 'application/json'
            })
        });
    }

    getDataUnits(level): Observable<any> {
        return this.http.get(this.apiUrl + 'data/unit?data_level=' + level, this.authHttpOptions());
    }

    getSubUnits(level, unit): Observable<any> {
        return this.http.get(this.apiUrl + 'data/sub_unit?data_level=' + level + '&data_unit=' + unit, this.authHttpOptions());
    }

    getDesignations(level, unit, subUnit = null): Observable<any> {
        return this.http.get(this.apiUrl + 'data/designations?data_level=' + level
            + '&data_unit=' + unit + '&sub_unit=' + subUnit, this.authHttpOptions());
    }

    createDipRule(data): Observable<any> {
        return this.http.post(this.customApiUrl + 'rule/create', data, this.authHttpOptions())
    }

    getDipRules(clientIds, state, type, level, unit, subUnit, designation, limit, offset): Observable<any> {
        return this.http.get(this.customApiUrl + 'rules?client_app_id=' + clientIds + '&state_id=' + state
            + '&data_type_id=' + type + '&data_level_id=' + level + '&data_unit_id=' + unit + '&sub_unit_id=' + subUnit +
            '&designation_id=' + designation + '&limit=' + limit + '&offset=' + offset, this.authHttpOptions());
    }

    deleteDipRules(ruleId): Observable<any> {
        return this.http.get(this.customApiUrl + 'rule/delete?id=' + ruleId, this.authHttpOptions());
    }

    applyDipRules(ruleId, apply: boolean): Observable<any> {
        return this.http.get(this.customApiUrl + 'rule/apply?id=' + ruleId + '&apply=' + apply, this.authHttpOptions());
    }

    getDipPermission(clientAppIds, levelId): Observable<any> {
        return this.http.get(this.customApiUrl + 'dip_permissions?data_level_id=' + levelId + '&client_app_id=' + clientAppIds, this.authHttpOptions())
    }

    deletePermissionRules(filters: any): Observable<any> {
        const url = `${this.customApiUrl}rule/delete`;
        return this.http.post(url, filters,this.authHttpOptions());
    }

}

