<h1 mat-dialog-title>Warning</h1>
<div *ngIf="action" class="d-flex justify-content-between flex-wrap">
    <h5 class="confirmation">{{'Are you sure you want to ' }} <p class="action">{{action}}</p>   {{' this user ?'}}</h5>
</div>
<div class="confirmation">{{message}}</div>
<div>
    <div class="button-container d-flex mt-5 justify-content-between">
        <button mat-button class="cancel-button w-50" (click)="onClick(false)" >{{'Cancel'|translate}}</button>
        <button mat-button class="confirm-button w-50" (click)="onClick(true)"  *ngIf="action">{{action |translate}}</button>
    </div>
</div>
