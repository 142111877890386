import {Component, OnInit} from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "../../services/message.service";
import {PageEvent} from "@angular/material/paginator";
import {UtilsService} from "../../services/utils.service";
import {MatDialog} from "@angular/material/dialog";
import {DownloadOptionsComponent} from "../shared/download-options/download-options.component";
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime} from "rxjs";
import {UploadUsersComponent} from "../shared/upload-users/upload-users.component";
import {LoaderService} from "../../services/loader.service";

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
    filterForm: FormGroup = new FormGroup({});
    locationList = ['Mandal', 'ShaktiKendra', 'Booth'];
    clientApps = [];
    showAllApps = false;
    visibleApps;
    remainingApps;
    userList = [];
    levels = [];
    callingRole = [{name: 'Team Lead', value: 'team_lead'}, {name: 'Agent', value: 'agent'}, {
        name: 'CCM',
        value: 'call_center_manager'
    }];
    pageSize = 15;
    offset = 0;
    locationType = '';
    length;
    pageNumber: number = 1;
    pageJumpSize: number;
    pageSizeOptions: number[] = [5, 10, 15, 25];
    isLoading: boolean;
    locationsDropdown = {};
    selected = 0;
    tabName = 'active';
    isDisabled = false;
    tab = '0';
    levelHierarchy: any;
    states = [];
    dropDowns: any[] = [];
    queryTypes = [{name: 'Phone Number', value: 'phone_number'}, {name: 'Name', value: 'name'}, {
        name: 'Email',
        value: 'email'
    }, {name: 'Username', value: 'username'}];
    is_count = false;
    countLoader:boolean = false;

    constructor(private apiService: RestApiService, private formBuilder: FormBuilder, private router: Router,
                private messageService: MessageService, public utils: UtilsService, private dialog: MatDialog, private route: ActivatedRoute, private loaderService: LoaderService) {
    }

    ngOnInit(): void {
        this.filterForm = this.formBuilder.group({
            query: new FormControl(''),
            state_id: new FormControl(null),
            level: new FormControl(''),
            location_id: new FormControl(''),
            calling_role: new FormControl(''),
            client_app_ids: new FormControl([]),
            query_type: new FormControl(this.queryTypes[0].value)
        });
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                this.selected = params['tab'];
                if (params['tab'] === '0') {
                    this.tabName = 'active';
                    this.tab = '0';
                    this.isDisabled = false;
                    this.resetPagination();
                } else if (params['tab'] === '1') {
                    this.tab = '1';
                    this.tabName = 'disabled';
                    this.isDisabled = true;
                    this.resetPagination();
                }
            }
        });
        this.getClientApps();
        this.getUserList();
        this.getDataLevels();
        this.onFormChanges();
        this.getCountryStates();
    }

    onFormChanges(): void {
        this.filterForm.get('level').valueChanges.subscribe(value => {
            this.locationsDropdown = {}
            this.clearNgSelect(0)
            this.resetPagination();
            this.filterForm.get('location_id').setValue('');
            if (value) {
                this.getCountryStates();
                this.getDataLevelHierarchy(value);
            }
        });
        this.filterForm.get('client_app_ids').valueChanges.subscribe(value => {
            this.resetPagination();
            this.getUserList();
        });
        this.filterForm.get('calling_role').valueChanges.subscribe(value => {

        });
        this.filterForm.get('state_id').valueChanges.subscribe(value => {

        });
        this.filterForm.get('query_type').valueChanges.subscribe(value => {
            this.resetPagination();
        });
        this.filterForm.get('query').valueChanges.subscribe(value => {
            this.resetPagination();
        });
    }

    getClientApps() {
        this.apiService.clientApps().subscribe(response => {
            const res = response as any;
            this.clientApps = res.data;
            this.visibleApps = this.clientApps.slice(0, 5);
            this.remainingApps = this.clientApps.slice(5);
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
        });
    }

    getUserList() {
        this.isLoading = true;
        this.isDisabled = this.tab !== '0';
        this.is_count = false;
        let state = ''
        if (this.filterForm.get('state_id').value) {
            state = this.filterForm.get('state_id').value
        }
        this.apiService.userList({locationType: this.locationType, disabled: this.isDisabled, locationId: this.filterForm.get('location_id').value, callingRole: this.filterForm.get('calling_role').value,
            query: this.filterForm.get('query').value, clientAppIds: this.filterForm.get('client_app_ids').value, dataLevelId: this.filterForm.get('level').value, limit: this.pageSize, offset: this.offset, stateId: state, queryType: this.filterForm.get('query_type').value, isCount: this.is_count}, {showLoader: false}).subscribe(response => {
            const res = response as any;
            this.userList = res.data;
            // this.length = res.length;
            this.pageJumpSize = 5;
            this.isLoading = false;
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
            this.isLoading = false;
        });
    }

    downloadUserList(email) {
        if (this.userList.length) {
            this.isDisabled = this.tab !== '0';
            let state = ''
            if (this.filterForm.get('state_id').value) {
                state = this.filterForm.get('state_id').value
            }
            this.apiService.userListDownload(email, this.locationType, this.isDisabled, this.filterForm.get('location_id').value, this.filterForm.get('calling_role').value,
                this.filterForm.get('query').value, this.filterForm.get('client_app_ids').value, this.filterForm.get('level').value, this.pageSize, this.offset, state, this.filterForm.get('query_type').value).subscribe((response: any) => {
                if (response) {
                    this.messageService.closableSnackBar(response.message, 5000);
                }
            }, error => {
                this.messageService.errorSnackBar(error, 5000);
                this.isLoading = false;
            });
        } else {
            this.messageService.closableSnackBar('No data to download', 5000);
        }
    }

    searchUser() {
        this.resetPagination()
        this.getUserList()
    }

    getCountryStates(): void {
        this.apiService.allottedCountryStates().subscribe(response => {
            const res = response as any
            this.states = res.data;
            this.locationsDropdown['CountryState'] = res.data;
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
        });
    }

    getDataLevels(): void {
        this.apiService.dataLevels().subscribe(response => {
            const res = response as any;
            this.levels = res.data;
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
        });
    }


    paginationClicked(event): PageEvent {
        this.pageNumber = event;
        this.offset = event === 0 ? 0 : (event * this.pageSize - this.pageSize);
        this.getUserList();
        return event;
    }

    pageSizeChanged(event): PageEvent {
        this.pageNumber = 1;
        this.pageSize = event;
        this.offset = 0;
        this.getUserList();
        return event;
    }

    clearFilters(): void {
        if (this.filterForm.get('level') || this.filterForm.get('location_id').value || this.filterForm.get('calling_role').value || this.filterForm.get('state_id').value) {
            this.filterForm.get('level').setValue('');
            this.filterForm.get('location_id').setValue('');
            this.filterForm.get('calling_role').setValue('');
            this.filterForm.get('state_id').setValue(null);
            this.locationType = '';
            this.userList = [];
            this.clearNgSelect(0)
            this.resetPagination();
            this.getUserList();
        } else {
            this.messageService.closableSnackBar('No Filters Selected');
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DownloadOptionsComponent, {
            width: '550px',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== 'false') {
                this.downloadUserList(result);
            }
        });
    }

    changeTab(event): void {
        this.tab = event.index.toString();
        this.pageSize = 100;
        this.resetPagination();
        this.getUserList();
        this.tabChange(event);
    }

    tabChange($event): any {
        this.router.navigate([], {
            queryParams: {
                tab: $event.index,
                user_type: $event.tab.textLabel
            },
            queryParamsHandling: 'merge',
        });
    }

    uploadUsersDialog(actionType): void {
        const dialogRef = this.dialog.open(UploadUsersComponent, {
            width: '600px',
            disableClose: true,
            data: {type: actionType}
        });
        dialogRef.afterClosed().subscribe(result => {

        });
    }

    getRequiredLocation(locationType, location, requiredLocationType, currentIndex: number): void {
        this.dropDowns[currentIndex] = location.id;
        if (currentIndex < this.dropDowns.length - 1) {
            this.clearNgSelect(currentIndex);
        }
        if (locationType !== requiredLocationType) {
            this.loaderService.show();
            this.apiService.requiredLocation(locationType, location.id, requiredLocationType, location.id, location.id).subscribe((response: any) => {
                this.loaderService.hide();
                this.locationsDropdown[requiredLocationType] = response.data.locations;
            }, error => {
                this.loaderService.hide()
                this.messageService.errorSnackBar(error);
            })
        } else if (locationType === requiredLocationType) {
            this.locationType = locationType;
            this.filterForm.get('location_id').setValue(location.id);
        }
    }

    resetPagination(): void {
        this.pageSize = 15;
        this.offset = 0;
        this.pageNumber= 1;
        this.length= '';
    }

    getDataLevelHierarchy(levelId): void {
        this.loaderService.show();
        this.apiService.dataLevelHierarchy(levelId).subscribe((response: any) => {
            this.levelHierarchy = response.data;
            this.loaderService.hide();
        }, error => {
            this.loaderService.hide();
            this.messageService.errorSnackBar(error);
        });
    }

    clearNgSelect(currentIndex): void {
        for (let i = currentIndex; i < this.dropDowns.length; i++) {
            this.dropDowns[i] = null;
        }
    }

    userListCount() {
        this.countLoader = true;
        this.isDisabled = this.tab !== '0';
        let state = ''
        this.is_count = true;
        if (this.filterForm.get('state_id').value) {
            state = this.filterForm.get('state_id').value
        }
        this.apiService.userList({locationType: this.locationType, disabled: this.isDisabled, locationId: this.filterForm.get('location_id').value, callingRole: this.filterForm.get('calling_role').value,
            query: this.filterForm.get('query').value, clientAppIds: this.filterForm.get('client_app_ids').value, dataLevelId: this.filterForm.get('level').value, limit: this.pageSize, offset: this.offset, stateId: state, queryType: this.filterForm.get('query_type').value, isCount: this.is_count}).subscribe(response => {
            const res = response as any;
            this.length = res.length.toLocaleString('en-IN');
            this.countLoader = false;
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
        });
    }

    setQueryType(selectedValue: string): void {
        this.filterForm.get('query_type').setValue(selectedValue);
        this.filterForm.get('query').setValue('');
    }

    toggleShowAllApps() {
        this.showAllApps = !this.showAllApps;
    }
}
