import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MessageService} from "../../services/message.service";
import {RestApiService} from "../../services/rest-api.service";
import {FormControl, Validators} from "@angular/forms";

@Component({
    selector: 'app-rule-access-popup',
    templateUrl: './rule-access-popup.component.html',
    styleUrls: ['./rule-access-popup.component.css']
})
export class RuleAccessPopupComponent implements OnInit {
    clientApp = new FormControl('')
    message: string;

    constructor(public dialogRef: MatDialogRef<RuleAccessPopupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any, private messageService: MessageService, private apiService: RestApiService) {
    }

    ngOnInit() {
        this.checkMessage()
    }

    checkMessage(): void {
        this.message = this.data.message;
        this.checkRuleType()
    }

    confirmation(result): void {
        this.dialogRef.close(result);
    }

    checkRuleType(): void {
        if (this.data.ruleType === 'single') {
            this.ruleApplyCheck();
        }
    }

    ruleApplyCheck(): void {
        this.apiService.applyDipRules(this.data.rule, false).subscribe((response: any) => {
            if (response) {
                this.message = 'Are you sure, you want to Apply this rule. This will be applied to ' + response.size + '  people'
            }
        }, error => {
            this.messageService.errorSnackBar(error);
        });
    }
}
