import {Component, HostBinding} from '@angular/core';
import {CustomIconService} from "./services/custom-icon.service";
import {TranslateService} from "@ngx-translate/core";
import {UtilsService} from "./services/utils.service";
import {Router} from "@angular/router";
import {MessageService} from "./services/message.service";
import {Idle} from 'idlejs/dist';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'sso-user-management';
    @HostBinding('class') componentCssClass: any;

    public constructor(public iconService: CustomIconService, private router: Router, private translate: TranslateService,
                       public utils: UtilsService) {
        const sysLanguage: string = 'en';
        translate.setDefaultLang(sysLanguage);
        this.translate.use(sysLanguage);
        this.iconService.init();
    }


    ngOnInit(): void {
        if (!this.utils.isLoggedIn()) {
            this.utils.clearLocalStorage();
        }
    }


}
