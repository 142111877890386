import {Injectable} from '@angular/core';
import {XlsxDownloadService} from "./xlsx-download.service";
import {ngxCsv} from "ngx-csv";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as Constant from '../constant'
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    phonePattern = '^[4-9][0-9]{9}$';
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$';
    passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$'
    // passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
    userId = '';
    isAuthorized = false;

    constructor(private xlsxDownloadService: XlsxDownloadService, private router: Router) {
    }

    isLoggedIn() {
        const authStatus: string = localStorage.getItem('authStatus');
        return authStatus && authStatus === 'true';
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        // Handle Backspace and Tab keys
        if (charCode === 8 || charCode === 9) {
            return true;
        }
        // Allow Ctrl+V or Cmd+V for paste functionality
        if ((evt.ctrlKey || evt.metaKey) && charCode === 86) {
            return true;
        }
        // Handle other keys
        return !((charCode > 31 && charCode < 48) || charCode > 57);
    }



    clearLocalStorage(): void {
        localStorage.setItem(Constant.AUTH_STATUS, 'false');
        localStorage.setItem(Constant.AUTH_TOKEN, '');
        localStorage.setItem(Constant.USERNAME, '');
        localStorage.setItem(Constant.USEREMAIL, '');
        localStorage.setItem(Constant.PERMISSIONS, '');
        localStorage.setItem(Constant.USERROLE, '');
        this.router.navigate(['/'])
    }

    toFormData<T>(formValue: T) {
        const formData = new FormData();

        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            formData.append(key, value);
        }

        return formData;
    }


    downloadCSV(headers, data, name): any {
        const file = name;
        const csvData = [headers].concat(data);
        return new ngxCsv(csvData, file + '.csv');
    }

    downloadXlsx(header, data, name): any {
        const headers = header;
        const xlsxData = data;
        const filename = name + `-${(new Date()).toString().substring(0, 24)}.xlsx`;
        this.xlsxDownloadService.download(headers, xlsxData, filename);
    }

    downloadPdf(headers, data, name): any {
        const doc = new jsPDF('l',
            'pt', 'A4');
        doc.setTextColor(242, 113, 35);
        doc.setFontSize(24);
        console.log(data);
        console.log(doc.getFontList());
        doc.text(name, 320, 30);
        (doc as any).autoTable({
            styles: {
                lineWidth: 1,
                rowPageBreak: 'auto',
                halign: 'center',
                pageBreak: 'auto',
            },
            headStyles: {fillColor: [90, 90, 97], textColor: '#ffffff', fontSize: 10},
            alternateRowStyles: {fillColor: [245, 245, 255]},
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.1,
            margin: {top: 40, left: 5, right: 5},
            head: [headers],
            body: data,
            theme: 'plain',
            didDrawCell: datas => {
                enhanceWordBreak(datas);
            }
        });
        // below line for Download PDF document
        doc.save(name + '.pdf');
    }

    public isStateAdmin() {
        return localStorage.getItem(Constant.USERROLE) === 'state_admin';
    }

    public isNational() {
        return localStorage.getItem(Constant.USERROLE) === 'national';
    }

    returnSelectedValue(id, array) {
        let name = ''
        array.forEach(item => {
            if (item.id === id) {
                name = item.name
            }
        });
        return name
    }

    arrayOfKey(arrayOfObject, key): Array<[]> {
        const array = [];
        arrayOfObject.forEach(aob => {
            array.push(aob[key]);
        });
        return array;
    }

    customSearchFn(searchTerm: string, item: any): boolean {
        // Perform a case-insensitive search on both the name and number properties
        searchTerm = searchTerm.toLowerCase();
        const nameMatches = item.name.toLowerCase().includes(searchTerm);
        const numberMatches = item.number && item.number.toString().includes(searchTerm);
        return nameMatches || numberMatches;
    }

    deleteExtraLevel(levelArray, levelNames) {
        levelNames.forEach(level => {
            const levelIndex = levelArray.findIndex(e => e.level_class === level);
            if (levelIndex !== -1) {
                levelArray.splice(levelIndex, 1);
            }
        })
        return levelArray;
    }

    isPortalAllowed(allPermissions: any, action: string): boolean {
        const permissionNames = ['SSO'];
        return allPermissions.some((permission: any) => {
            return permissionNames.includes(permission.permission_name) && permission.action === action;
        });
    }

}

function enhanceWordBreak({doc, cell, column}): any {
    if (cell === undefined) {
        return;
    }

    const hasCustomWidth = (typeof cell.styles.cellWidth === 'number');

    if (hasCustomWidth || cell.raw == null || cell.colSpan > 1) {
        return;
    }

    let text;

    if (cell.raw instanceof Node) {
        text = cell.raw.innerText;
    } else {
        if (typeof cell.raw === 'object') {
            return;
        } else {
            text = '' + cell.raw;
        }
    }
    const words = text.split(/\s+/);

    // calculate the longest word width
    const maxWordUnitWidth = words.map(s => Math.floor(doc.getStringUnitWidth(s) * 100) / 100).reduce((a, b) => Math.max(a, b), 0);
    const maxWordWidth = maxWordUnitWidth * (cell.styles.fontSize / doc.internal.scaleFactor);

    const minWidth = cell.padding('horizontal') + maxWordWidth;

    // update minWidth for cell & column

    if (minWidth > cell.minWidth) {
        cell.minWidth = minWidth;
    }

    if (cell.minWidth > cell.wrappedWidth) {
        cell.wrappedWidth = cell.minWidth;
    }

    if (cell.minWidth > column.minWidth) {
        column.minWidth = cell.minWidth;
    }

    if (column.minWidth > column.wrappedWidth) {
        column.wrappedWidth = column.minWidth;
    }
}
