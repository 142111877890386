import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RestApiService} from "../../services/rest-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UtilsService} from "../../services/utils.service";
import {MessageService} from "../../services/message.service";

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
    createUser: FormGroup = new FormGroup({});
    phone = new FormControl('', [Validators.minLength(10), Validators.pattern(this.utils.phonePattern)]);
    hide = true;
    croppedImage: any = '';
    disabled = false;
    editMode = false;
    message: string;
    buttonText = 'Create User';
    isLoading: boolean;
    photo = new FormControl('');

    constructor(private formBuilder: FormBuilder, private apiService: RestApiService, private router: Router, private activatedRoute: ActivatedRoute,
                public utils: UtilsService, private messageService: MessageService, private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.createUser = this.formBuilder.group({
            phone_number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.pattern(this.utils.phonePattern)]),
            name: new FormControl('', [Validators.required, Validators.pattern('^[\u0900-\u097F a-zA-Z\\s]+$')]),
            password: new FormControl(''),
            username: new FormControl(''),
            email: new FormControl('', [Validators.required, Validators.pattern(this.utils.emailPattern)]),
            gender: new FormControl(''),
            photo: new FormControl(),
            user_id: new FormControl(''),
        });
        this.activatedRoute.queryParams.subscribe(params => {
            if (params['phone']) {
                if (params['id']) {
                    this.editMode = true;
                    this.createUser.get('password').removeValidators([Validators.required, Validators.pattern(this.utils.passwordPattern), Validators.minLength(6)])
                    this.createUser.get('password').updateValueAndValidity();
                }
                this.createUser.get('phone_number').setValue(params['phone']);
                this.getUserDetails('', params['id']);
                this.buttonText = 'Save User';
                this.phone.setValue(params['phone']);
                this.editMode = true;
            }
        });
        this.onFormChanges();
    }

// ### <----- Method for handling the changes of form fields -----> #####
    onFormChanges(): void {
        this.createUser.get('phone_number').valueChanges.subscribe(value => {
            // #### <---- Calling the api if phone number is valid -----> ####
            // Without clicking the button
            if (this.createUser.get('phone_number').valid) {
                this.getUserDetails(value);
                // ## disabling the button
            } else {
                this.disabled = false;
                this.message = '';
                this.clearFormFields();
            }
        });
    }

// ### Getting User Details By Phone Number ######
    getUserDetails(phone, userId = ''): void {
        this.isLoading = true;
        this.apiService.searchUser(userId, phone).subscribe(response => {
            const res = response as any;
            this.isLoading = false;
            if (res.data.phone_number) {
                this.utils.userId = res.data.id;
                this.message = res.message;
                this.setFormFields(res.data);
                this.buttonText = 'Save User';
                this.disabled = true;
                this.createUser.get('password').removeValidators([Validators.required]);
                this.createUser.get('password').updateValueAndValidity();
            } else {
                this.message = res.message;
                this.isLoading = false;
                this.createUser.get('password').setValidators([Validators.required, Validators.pattern(this.utils.passwordPattern), Validators.minLength(6)]);
                this.createUser.get('password').updateValueAndValidity();
            }
        }, error => {
            this.isLoading = false;
            this.messageService.errorSnackBar(error, 6000);
        });
    }

    // ###### <------ Setting the form of create user according to the response of api ------> ###
    setFormFields(userData): void {
        this.createUser.get('name').setValue(userData.name);
        this.createUser.get('user_id').setValue(userData.id);
        this.createUser.get('username').setValue(userData.username);
        this.createUser.get('email').setValue(userData.email);
        this.createUser.get('gender').setValue(userData.gender);
        this.createUser.get('photo').setValue(userData.avatar);
        this.croppedImage = userData.avatar;
    }

    // ### <---- Calling the post api of create user with the create user form values in body ----> ###
    createNewUser(): void {
        this.isLoading = true;
        if (this.editMode) {
            this.createUser.get('phone_number').setValue(this.phone.value);
        }
        this.apiService.createUser(this.createUser.value).subscribe(response => {
            const res = response as any;
            this.isLoading = false;
            this.createUser.reset();
            this.router.navigate(['/index/user/details/' + res.data.id])
            this.messageService.closableSnackBar('User has been Created Successfully', 5000);
        }, error => {
            this.messageService.errorSnackBar(error);
            this.isLoading = false;
        });
    }

    onFileChange(event, control) {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].type.includes('image')) {
                const [file] = event.target.files;
                this[control].setValue({
                    file
                });
                if (control === 'photo') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (ev) => {
                        console.log('image loaded');
                        const fileReader = ev.target as FileReader;
                        this.croppedImage = fileReader.result;
                    };
                    this.cd.markForCheck();
                }
                this.uploadPhoto(control);
            } else {
                this.photo.setValue('')
                this.messageService.errorSnackBar('Not a valid format')
            }

        }
    }

    uploadPhoto(control) {
        this.apiService.uploadPhoto(this.utils.toFormData(this[control].value)).subscribe((response: any) => {
            this.createUser.get('photo').setValue(response.data.data);
        }, error => {
            this.messageService.errorSnackBar(error, 5000);
        });
    }

    clearFormFields(): void {
        this.createUser.get('name').reset();
        this.createUser.get('username').reset();
        this.createUser.get('email').reset();
        this.createUser.get('gender').reset();
        this.createUser.get('photo').reset();
        this.croppedImage = '';
    }

    clearImage(): void {
        this.createUser.get('photo').reset();
        this.croppedImage = '';
    }

}
