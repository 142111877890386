<div class="container-fluid login-container overflow-hidden" [ngClass]="buttonDisable ? 'cursor-wait': ''">
  <img class="login-background" src="../../assets/images/login-background.png" alt="image">
  <div class="login-form-container float-md-end">
    <h4 class="title">{{'SSO Login'|translate}}</h4>
    <form class="login-form d-flex flex-column mt-5" [formGroup]="loginForm">
      <ng-container *ngIf="showOTPButton">
        <mat-label>{{'Email'|translate}}</mat-label>
        <mat-form-field appearance="outline" class="login-form-field mt-1">
          <input matInput type="email" placeholder="{{'Email Address'|translate}}" formControlName="email"
                 name="email"  #emailInput>
          <mat-error class="modal-error" *ngIf="loginForm.get('email').hasError('required')">Email is required</mat-error>
          <mat-error class="modal-error" *ngIf="loginForm.get('email').hasError('pattern')">Please enter valid email</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="showOTPButton">
        <mat-label>{{'Password'|translate}}</mat-label>
        <mat-form-field appearance="outline" class="login-form-field mt-1">
          <input matInput [type]="hide ? 'password' : 'text'"
                 name="password" formControlName="password"
                 placeholder="{{'Password'|translate}}"
                 required minlength="6" maxlength="25">
          <mat-icon matSuffix class="cursor-pointer" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error class="modal-error" *ngIf="this.loginForm.get('password').hasError('required')">Password is
            required
          </mat-error>
          <mat-error class="modal-error" *ngIf="this.loginForm.get('password').hasError('minlength')">Password should be
            at least 6
            characters
          </mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="showOTPButton">
        <button mat-raised-button class="login-button login-form-field mt-4"
                (click)="otpClicked()" [disabled]="buttonDisable">{{loginButtonText}}
        </button>
      </ng-container>
      <ng-container *ngIf="!showOTPButton">
          <mat-icon class="show-hide" (click)="this.config.isPasswordInput = !this.config.isPasswordInput">{{this.config.isPasswordInput ? 'visibility_off' : 'visibility'}}</mat-icon>
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
        <div class="time d-flex justify-content-end mt-1 mb-1" *ngIf="!disabled">{{display}} </div>
        <button class="resend d-flex justify-content-start mt-2 mb-1 w-25" mat-button (click)='resend()' *ngIf="disabled" [disabled]="otp">Resend Otp</button>
        <button mat-button (click)="loginClicked()" class="login-button  login-form-field mt-1">Log In</button>
      </ng-container>
    </form>
  </div>
</div>

