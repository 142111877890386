import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './login/login.component';
import {HeaderComponent} from './shared/header/header.component';
import {ConfirmDialogComponent} from './shared/confirm-dialog/confirm-dialog.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {IndexComponent} from './user-management/index/index.component';
import {MatMenuModule} from "@angular/material/menu";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {NgOtpInputModule} from "ng-otp-input";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTabsModule} from "@angular/material/tabs";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { UserTableComponent } from './user-management/shared/user-table/user-table.component';
import {MatChipsModule} from "@angular/material/chips";
import { AddUserComponent } from './user-management/add-user/add-user.component';
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import { UserDetailsComponent } from './user-management/shared/user-details/user-details.component';
import { ManageUserComponent } from './user-management/manage-user/manage-user.component';
import { AppAssignmentComponent } from './user-management/shared/app-assignment/app-assignment.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { TruncateTextPipe } from './services/trumcate-text.pipe';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { DownloadOptionsComponent } from './user-management/shared/download-options/download-options.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import { UploadUsersComponent } from './user-management/shared/upload-users/upload-users.component';
import { ErrorPageComponent } from './shared/error-page/error-page.component';
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserTagComponent } from './user-management/shared/user-tag/user-tag.component';
import {ClientAppsAssignmentComponent} from "./user-management/shared/client-apps-assignment/client-apps-assignment.component";
import { LoaderComponent } from './shared/loader/loader.component';
import {LoaderInterceptor} from "./interceptors/loader.interceptor";
import { DipRulesComponent } from './dip-rules/dip-rules.component';
import { CreateDipRulesComponent } from './create-dip-rules/create-dip-rules.component';
import {MatDividerModule} from "@angular/material/divider";
import { RuleAccessPopupComponent } from './shared/rule-access-popup/rule-access-popup.component';
import { ViewRolesComponent } from './shared/view-roles/view-roles.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {AuthGuard} from "./auth.guard";
import {NgOptimizedImage} from "@angular/common";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ConfirmDialogComponent,
    IndexComponent,
    UserTableComponent,
    AddUserComponent,
    UserDetailsComponent,
    ManageUserComponent,
    AppAssignmentComponent,
    SnackbarComponent,
    BreadcrumbComponent,
    TruncateTextPipe,
    DownloadOptionsComponent,
    UploadUsersComponent,
    ErrorPageComponent,
    UserProfileComponent,
    UserTagComponent,
    ClientAppsAssignmentComponent,
    LoaderComponent,
    DipRulesComponent,
    CreateDipRulesComponent,
    RuleAccessPopupComponent,
    ViewRolesComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        HttpClientModule,
        MatMenuModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        NgOtpInputModule,
        MatDialogModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatCardModule,
        MatSelectModule,
        MatRadioModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        NgxSkeletonLoaderModule,
        MatDividerModule,
        NgSelectModule,
        NgOptimizedImage
    ],
  providers: [
      AuthGuard,
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
