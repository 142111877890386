<div class="container-md">
    <div class="breadcrumb-container mt-2">
        <app-breadcrumb></app-breadcrumb>
    </div>
    <div class="title d-flex justify-content-between mb-3">
        <h3 class="d-flex align-items-center">
            <mat-icon class="title-icon mr-5" svgIcon="dip" matPrefix></mat-icon>
            {{'Rule based access management' | translate}}</h3>
        <button class="create-button" [routerLink]="'/index/rule-based-access-management/create-rules'">
            <mat-icon class="plus-icon mr-3" svgIcon="plus" matPrefix></mat-icon>
            {{'Add Rule'|translate}}
        </button>
    </div>
    <mat-card class="filter-card">
        <div class="label mb-3">
            <h4 class="text">{{'Filters' |translate}}</h4>
        </div>
        <div class="filters d-flex  flex-wrap w-100">
            <form [formGroup]=filters>
                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                    <mat-label>{{'Select Client App'| translate}}</mat-label>
                    <mat-select formControlName="client_app_id" disableOptionCentering>
                        <mat-option class="options" *ngFor="let option of clientApps"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                    <mat-label>{{'Select State'| translate}}</mat-label>
                    <mat-select formControlName="state_id" disableOptionCentering>
                        <mat-option class="options" *ngFor="let option of states"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                    <mat-label>{{'Select Data Type'| translate}}</mat-label>
                    <mat-select formControlName="data_type_id" disableOptionCentering>
                        <mat-option class="options" *ngFor="let option of dataType"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                    <mat-label>{{'Select Level'| translate}}</mat-label>
                    <mat-select formControlName="data_level_id" disableOptionCentering>
                        <mat-option class="options" *ngFor="let option of dataLevel"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                    <mat-label>{{'Select Unit'| translate}}</mat-label>
                    <mat-select formControlName="data_unit_id" disableOptionCentering>
                        <mat-option class="options" *ngFor="let option of dataUnit"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                    <mat-label>{{'Select Sub Unit'| translate}}</mat-label>
                    <mat-select formControlName="sub_unit_id" disableOptionCentering>
                        <mat-option class="options" *ngFor="let option of subUnit"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                    <mat-label>{{'Select Designation'| translate}}</mat-label>
                    <mat-select formControlName="designation_id" disableOptionCentering>
                        <mat-option class="options" *ngFor="let option of designation"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="w-100 justify-content-end">
                     <button mat-button class="clear-button create-button" (click)="clear()">{{'Clear'|translate}}</button>
                    <button mat-button class="create-button" (click)="getFilterRules()">{{'Submit'|translate}}</button>
                </span>
            </form>
        </div>

    </mat-card>
    <ng-container *ngIf="accesses?.length; else noData">
        <mat-card class="d-flex mt-2">
            <div class="container-fluid dip-table-container">
                <ng-container *ngIf="isLoading">
                    <ngx-skeleton-loader
                            [theme]="{ 'border-radius': '5px', height: '50px','background-color': '#b2c8f6', border: '1px solid white' }"
                            animation="pulse" count="5"></ngx-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!isLoading">
                    <div class="select-all-container d-flex gap-3 justify-between align-items-center">
                        <mat-checkbox [(ngModel)]="selectAll" (change)="toggleSelectAll()">
                            Select All
                        </mat-checkbox>
                        <div style="color: #F76566;" *ngIf="selectAll">
                            Total {{ allRules ? length : selectedRules.length }} rules are selected.
                            <button mat-button *ngIf="length - accesses.length > 0" class="multi-delete-clear" (click)="deleteAllRules()" >
                                {{allRules ? "clear selection" : "Select remaining all " + (length - accesses.length) + " rules."  }}
                            </button>
                        </div>
                        <button mat-stroked-button class="multi-delete-btn d-flex"
                                *ngIf=" selectAll || selectedRules.length > 0" (click)="deleteSelectedRules()">
                            <mat-icon class="icon" matPrefix style="color: red">close</mat-icon>
                            {{'Delete Selected' |translate}}
                        </button>
                    </div>

                    <!-- DIP Table Heading Container Start -->
                    <div class="dip-table-heading-container">
                        <div class="row">
                            <div class="col-10">
                                <div class="row ms-2">
                                    <div class="col-2" *ngFor="let header of headerItem">
                                        <span class="header-item">{{header.name}}</span>
                                    </div>
                                    <div>
                                        <button  matTooltip="Download all Rules" style="margin: .7% 0 0 0;" mat-button (click)="downloadCreatedRules()" [disabled]="downloadButton" class="download-button">
                                            <mat-icon class="download-icon " svgIcon="download"></mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- DIP Table Heading Containr End -->

                    <div class="dip-card-container">
                        <div *ngFor="let cardItem of accesses; let i = index" class="card" [ngClass]="cardItem.apply ? 'dip-card' : 'dip-card'">
                            <div class="dip-card-header p-3">
                                <div class="row">
                                    <div class="col-1">
                                        <mat-checkbox [(ngModel)]="cardItem.selected" (change)="onCheckboxChange(cardItem.id, cardItem.selected)"></mat-checkbox>
                                    </div>
                                    <div class="col-9">
                                        <div class="row mt-2">
                                            <span class="card-header-item col-2 border-r-2">{{cardItem.country_state.name}}</span>
                                            <span class="card-header-item col-2 border-r-2">{{cardItem.data_type.name}}</span>
                                            <span class="card-header-item col-2 border-r-2">{{cardItem.data_level.name}}</span>
                                            <span class="card-header-item col-2 border-r-2">{{cardItem.data_unit.name}}</span>
                                            <span class="card-header-item col-2 border-r-2">{{cardItem.sub_unit.name}}</span>
                                            <span class="card-header-item col-2">{{cardItem.designation.name}}</span>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="cardItem.processing_aasm_state !== 'processing'">
                                        <div class="col-2 d-flex align-items-center justify-content-between btn-padding-right" *ngIf="cardItem.processing_aasm_state === 'stable'">
                                            <button mat-stroked-button class="dip-edit-card-button" color="primary" (click)="editRule(cardItem)">
                                                <mat-icon class="icon" matPrefix>edit</mat-icon>
                                                {{'Edit'|translate}}
                                            </button>
                                            <button mat-stroked-button class="dip-remove-card-button" color="primary" (click)="openDialog(cardItem)">
                                                <mat-icon class="icon" matPrefix style="color: red">close</mat-icon>
                                                {{'Remove' |translate}}
                                            </button>

                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="mt-2">
                                <mat-divider class="card-body-divider"></mat-divider>
                            </div>
                            <div class="d-flex justify-between ml-3 mt-2 flex-wrap">
                                <span class="access-label w-100">Rule Details :</span>
                                <div class="d-flex">
                                    <div class="d-flex">
                                        <span class="permission-key mt-2">{{'Rule Id: -  '}}{{cardItem.id + ','}}</span>
                                        <span class="permission-key mt-2 ml-3 mr-3">{{'Client Apps :'}}</span>
                                        <span class="permission-key mt-2 mr-3">{{cardItem.client_app?.name}}</span>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <span class="permission-key mt-2 mr-3">{{'Total User: - '}}{{cardItem.total_users}}</span>
                                        <span class="permission-key mt-2 ml-2">{{'Total Users Affected: - '}}{{cardItem.total_users_affected}}</span>
                                    </div>
                                </div>
                                <div *ngIf="cardItem.total_users_affected > 0" class=" mr-20">
                                    <mat-icon matTooltip="Download Affected User List"  class="download-icon" svgIcon="download" (click)="ruleUserDownloadDialog(cardItem)"></mat-icon>
                                </div>
                            </div>

                            <div class="d-flex justify-content-start p-3">
                                <span class="access-label w-100">Access :</span>
                            </div>
                            <div class="d-flex flex-wrap permissions" [class.show]="show[i]">
                                <div class="d-flex w-50 pl-2 border-r-2 mb-2"
                                     *ngFor="let item of cardItem.permissions.name | keyvalue">
                                    <div class="access-content d-flex flex-wrap"><div class="permission-key">{{item.key + ' -  '}} </div>
                                        <div class="break-words" style="word-break: break-all">{{item.value}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end w-100 pr-3 pb-2">
                                <button (click)="toggle(i)" class="show-button"
                                        *ngIf="getObjectKeysLength(cardItem.permissions.name)> 2">{{ show[i] ? 'Show less ^' : 'Show More' }}</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-card>
        <div class="paginator" *ngIf="accesses.length">
            <ngb-pagination
                    [pageSize]="pageSize" [collectionSize]="length"
                    [maxSize]="5"  [(page)]="pageNumber"
                    (pageChange)="pageChanged($event)"  size="sm"
                    [ellipses]="true" >
                <ng-template ngbPaginationPrevious>&nbsp;<&nbsp;&nbsp;&nbsp;Previous&nbsp;&nbsp;</ng-template>
                <ng-template ngbPaginationNext>&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;>&nbsp;</ng-template>
            </ngb-pagination>

            <div class="pageSize">
                <div class="pageSizeSelect">
                    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="paginationClicked(pageSize)">
                        <option *ngFor="let ps of pageSizeOptions" [ngValue]="ps">{{ps}}</option>
                    </select>
                </div>
                <span class="pageSizeSpan">Items per page</span>
            </div>
        </div>
    </ng-container>
    <div class="download-button">
    </div>
    <ng-template #noData>
        <div class="w-100 text-center mt-5">
            <h3 class="no-data-text">{{'No Rules Has Been Created'}}</h3>
        </div>
    </ng-template>
</div>
