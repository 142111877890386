export const AUTH_STATUS = 'authStatus';
export const USER_ID = 'userId';
export const AUTH_TOKEN = 'authToken';
export const USERNAME = 'userName';
export const USEREMAIL = 'userEmail';
export const USERPHONE = 'userPhone';
export const PERMISSIONS = 'permissions';
export const USERROLE = 'userRole';
export const STATES = 'states';
export const COUNTRY_STATE = 'countryState';
export const SYSTEM_LANGUAGE = 'systemLanguage';
export const MANAGER_LOCATION = 'managerLocation';
export const MANAGER_TYPE = 'managerType';
export const IS_MANAGER_ROLE = 'isManagerRole';
export const ALL_ROLES = 'allRoles';
export const USER_IMAGE = 'userImage';
export const USER_ROLE_ID = 'userRoleId'
