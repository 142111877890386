import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {MatDialog} from "@angular/material/dialog";
import * as Constant from '../../constant';
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() openDrawer = new EventEmitter();
  userName: string;
  requestInProgress = false;
  constructor(private restService: RestApiService, private dialogRef: MatDialog, private utils: UtilsService) { }

  ngOnInit(): void {
    this.userName = localStorage.getItem('userName')
  }

  logout() {
    if (!this.requestInProgress) {
      this.requestInProgress = true;
      setTimeout(_ => {
        if (this.isLoggedIn()) {
          this.utils.clearLocalStorage();
        }
      }, 1000);

      this.restService.logout().subscribe(reply => {
        const response = reply as any;
        this.requestInProgress = false;
        this.utils.clearLocalStorage();
      });
    }
    this.closeAllMatDialogs();
  }

  isLoggedIn() {
    const authStatus = localStorage.getItem(Constant.AUTH_STATUS);
    return authStatus && authStatus === 'true';
  }

  closeAllMatDialogs() {
    if (this.dialogRef) {
      this.dialogRef.closeAll();
    }
  }
}
