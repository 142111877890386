import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
errorCode: string;
  constructor(private activatedRoute: ActivatedRoute,  private location: Location, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      this.errorCode = 'error-' + params['error'];
    });
  }

  // Back on previous location
  back() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/campaign_users/index']);
    }
  }
}
