<div class="container-md">
    <div class="breadcrumb-container mt-2">
        <app-breadcrumb></app-breadcrumb>
    </div>
    <mat-card class="filter-card justify-content-between">
        <form [formGroup]=createRules class="d-flex justify-content-between flex-wrap">
            <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                <mat-label>{{'Select Client App'| translate}}</mat-label>
                <mat-select formControlName="client_app_id" disableOptionCentering [panelClass]="isEdit ? 'hidden': ''">
                    <mat-option class="options" *ngFor="let option of clientApps"
                                [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                <mat-label>{{'Select State'| translate}}</mat-label>
                <mat-select formControlName="state_id" disableOptionCentering [panelClass]="isEdit ? 'hidden': ''">
                    <mat-option class="options" *ngFor="let option of state"
                                [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                <mat-label>{{'Select Data Type'| translate}}</mat-label>
                <mat-select formControlName="data_type_id" disableOptionCentering [panelClass]="isEdit ? 'hidden': ''">
                    <mat-option class="options" *ngFor="let option of dataType"
                                [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                <mat-label>{{'Select Level'| translate}}</mat-label>
                <mat-select formControlName="data_level_id" disableOptionCentering [panelClass]="isEdit ? 'hidden': ''">
                    <mat-option class="options" *ngFor="let option of dataLevel"
                                [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                <mat-label>{{'Select Unit'| translate}}</mat-label>
                <mat-select formControlName="data_unit_id" disableOptionCentering [panelClass]="isEdit ? 'hidden': ''">
                    <mat-option class="options" *ngFor="let option of dataUnit"
                                [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                <mat-label>{{'Select Sub Unit'| translate}}</mat-label>
                <mat-select formControlName="sub_unit_id" disableOptionCentering [panelClass]="isEdit ? 'hidden': ''">
                    <mat-option class="options" *ngFor="let option of subUnit"
                                [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field" [style.FontSize.px]="11">
                <mat-label>{{'Select Designation'| translate}}</mat-label>
                <mat-select formControlName="designation_id" disableOptionCentering [panelClass]="isEdit ? 'hidden': ''">
                    <mat-option class="options" *ngFor="let option of designation"
                                [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </mat-card>

    <mat-card class="permission-card mt-2" *ngIf="createRules.get('designation_id').value">
        <div class="d-flex justify-content-end" *ngIf="allPermissions.length">
            <mat-checkbox class="single-checkbox d-flex min-w-[20%] mb-2" (change)="selectAllPermission($event)"
                          [checked]="selectedPermissions.length === allPermissions.length">
                {{'Select All Permission'}}
            </mat-checkbox>
        </div>
        <div class="expansion-panel d-flex flex-wrap w-100" *ngFor="let group of appPermissions | keyvalue">
            <ng-container *ngFor="let permission of group.value | keyvalue">
                <label class="permission-name w-100 mt-2 mb-2">{{permission.key}}</label>
                <ng-container *ngFor="let option of getValue(permission.value)">
                    <mat-checkbox class="single-checkbox d-flex min-w-[20%] mb-2"
                                  (change)="permissionSelection($event, option.id)"
                                  [checked]="selectedPermissions.includes(option.id)">
                        {{option.action}}
                    </mat-checkbox>
                </ng-container>
            </ng-container>
        </div>
        <div class="divide-amber-50 justify-content-center d-flex" *ngIf="!allPermissions.length">
            <label class="permission-name w-100 mt-3">{{'No Permission has created for this client app at selected level'}}</label>
        </div>
        <div class="d-flex w-100 justify-content-center mt-3">
            <button mat-button class="button" [disabled]="createRules.invalid" type="submit"
                    (click)="openDialog()">{{'Submit' | translate}}</button>
        </div>
    </mat-card>
</div>
